import styled from "styled-components";

/**
 * Create a block that contains an aspect ratio.
 * @param aspect - Should be the (height / width) of the aspect ratio you want.
 * ```jsx
 * <Aspect aspect={9 / 16} />
 * ```
 */
export const Aspect = styled.div<{ aspect?: number }>`
  position: relative;
  padding-bottom: ${(p) => (p.aspect ? p.aspect * 100 : 0)}%;
  width: 100%;
`;

/**
 * Basic styling blocks, that can be reused
 * */
export const Fill = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;
