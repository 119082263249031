import styled from "styled-components";

import CtaArrowIcon, { Props as CtaArrowIconProps } from "@/icons/CtaArrowIcon";
import { durations } from "@/styles/animations";
import { color, Theme } from "@/styles/theme";

type Props = {
  theme: Theme;
  hover?: boolean;
};

const StyledCtaArrow = styled(CtaArrowIcon)<Props>`
  color: ${(p: Props) => (p.theme.style === "dark" ? color.white : color.blue)};
  color: ${(p: Props) => (p.hover ? color.altBlue : null)};
  transition: color ${durations.fast} ease-out;
` as React.FunctionComponent<CtaArrowIconProps & Props>;

export default StyledCtaArrow;
