import useInteraction, {
  InteractionState,
} from "@charlietango/use-interaction";
import * as React from "react";
import styled from "styled-components";

import Link from "@/components/Link/Link";
import { focusOutline } from "@/styles/style-helpers";
import { fontFamily } from "@/styles/theme";
import { ButtonStateTypes } from "@/types/ButtonStateTypes";
import { TrackingTypes } from "@/types/TrackingTypes";
import generateTrackingAttributes from "@/utils/generateTrackingAttributes";
import { LinkViewModel } from "@/view-models/LinkViewModel";

export type BaseButtonProps = LinkViewModel & {
  children?: React.ReactNode | ((state: ButtonStateTypes) => React.ReactNode);

  /** Always render as anchor tag, don't fall back to button */
  isAnchor?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";

  /** Get the current interaction state of the button */
  onInteraction?: (...args: Array<any>) => any;
  onClick?: (e?: any) => void;
  onFocus?: (e?: any) => void;
  onKeyDown?: (e?: any) => void;
  tracking?: TrackingTypes;
  tabindex?: number;
};

/**
 * Basic button styles
 **/
const StyledButton = styled.button<BaseButtonProps>`
  display: inline-block;
  position: relative;
  padding: 0;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  font-family: ${fontFamily};
  text-align: left;
  ${focusOutline()};
  outline-offset: 4px;

  &[disabled] {
    cursor: default;
  }
`;

const BaseButton = React.forwardRef(
  (
    {
      href,
      type,
      disabled,
      isAnchor,
      children,
      onInteraction,
      tracking,
      tabindex,
      ...rest
    }: BaseButtonProps,
    ref
  ) => {
    const hasWrapFunc = typeof children === "function";
    const renderAsAnchor = isAnchor || (!!href && !disabled);

    const [intersectionRef, status] = useInteraction({
      onInteraction,
      skip: !hasWrapFunc && !onInteraction,
    });

    const refs = React.useCallback(
      (node) => {
        if (ref && intersectionRef) {
          //@ts-ignore
          ref.current = node;
          intersectionRef(node);
        }
      },
      [ref, intersectionRef]
    );

    const inner = hasWrapFunc
      ? (children as (status: InteractionState) => React.ReactNode)(status)
      : children;

    if (renderAsAnchor) {
      return (
        <StyledButton
          ref={refs}
          as={Link}
          href={href}
          {...rest}
          {...generateTrackingAttributes(tracking)}
        >
          {inner}
        </StyledButton>
      );
    }

    return (
      <StyledButton
        ref={refs}
        disabled={disabled || undefined}
        type={type}
        {...generateTrackingAttributes(tracking)}
        {...rest}
      >
        {inner}
      </StyledButton>
    );
  }
);

BaseButton.displayName = "BaseButton";
BaseButton.defaultProps = {
  type: "button",
};

export default BaseButton;
