import { Action, createMemoryHistory, History, Location } from "history";
import { useAtom } from "jotai";
import * as React from "react";
import { Optional } from "utility-types";

import { historyAtom, locationAtom } from "@/store";
import { DawaItemDataType } from "@/types/DawaItemDataType";

export type { History, Location };

type MethodToType = {
  pathname: string;
  search: string;
  hash: string;
};

type StateType = DawaItemDataType;

/** 🩹✨ ≈ https://github.com/ReactTraining/history/issues/859 */
const fixParams =
  (method: (to: MethodToType | string, state: StateType) => void) =>
  (newLocation: MethodToType | string, state: StateType) => {
    const window = global && global.window;
    const base = new URL(window?.location?.origin ?? window?.location?.href);

    const { pathname, search, hash } = new URL(
      typeof newLocation === "string"
        ? newLocation
        : newLocation?.pathname ?? window?.location?.pathname,
      base
    );
    if (typeof newLocation === "string") {
      return method(
        {
          pathname,
          search,
          hash,
        },
        { ...state }
      );
    }

    return method(
      {
        pathname: pathname || newLocation.pathname,
        search: search || newLocation.search,
        hash: hash || newLocation.hash,
      },
      { ...state }
    );
  };

export const RouteContext = React.createContext<Optional<History<any>>>({
  action: Action.Push,
  location: {
    pathname: "/",
    hash: "",
    search: "",
    state: {},
    key: "",
  },
});

type Props = {
  location?: string;
  children?: React.ReactNode;
};

/***
 * Router exposes the history instance, allowing you to consume it with the Context API.
 * We are not using `react-router`, since we don't need the actual routing functionality - just the history.
 *
 * ```
 * <RouteContext.Consumer>
 *   {history => (
 *    <Component history={history} />
 *   )}
 * </RouteContext.Consumer>
 * ```
 */
function Router(props: Props) {
  const [
    history = process.env.SERVER
      ? createMemoryHistory({
          initialEntries: [
            props.location
              ? props.location.substring(props.location.indexOf("/"))
              : "/",
          ],
        })
      : undefined,
  ] = useAtom(historyAtom);

  const [location = history?.location, setLocation] = useAtom(locationAtom);

  React.useEffect(() => {
    if (!history) return () => {};

    const listener = history.listen(({ location }) => {
      setLocation(location);
    });

    return () => listener();
  }, [history, location, setLocation]);

  const routerValue = React.useMemo(() => {
    if (!history) return {};

    return {
      block: history.block,
      replace: fixParams(history.replace),
      push: fixParams(history.push),
      listen: history.listen,
      action: history.action,
      location,
    };
  }, [history, location]);

  return (
    <RouteContext.Provider value={routerValue}>
      {props.children}
    </RouteContext.Provider>
  );
}

Router.displayName = "Router";

// Create a HOC to wrap with the context
export function withRouter(Component: React.ComponentType<any>) {
  // ...and returns another component...
  function RouteComponent(props: { [key: string]: any }) {
    // ... and renders the wrapped component with the context route!
    return (
      <RouteContext.Consumer>
        {(history) => (
          <Component {...props} history={history} location={history.location} />
        )}
      </RouteContext.Consumer>
    );
  }
  RouteComponent.displayName = "withRouter";

  return RouteComponent;
}

export function useRouter(): History {
  return React.useContext(RouteContext) as History;
}

export default Router;
