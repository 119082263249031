import { createGlobalStyle } from "styled-components";

import { ROOT_ID } from "../App/constants";
import { sanitize } from "./sanitize";
import { color, fontFamily } from "./theme";

/**
 * Global styles to align the page
 */
const GlobalStyles = createGlobalStyle`
  ${sanitize}

  html {
    margin: 0;
    height: 100%;
    width: 100%;
    background-color: ${color.bg};
    color: ${color.blue};
    font-family: ${fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    text-size-adjust: 100%;
  }

  body {
    margin: 0;
    width: 100%;
    height: 100vh;
  }

  #${ROOT_ID} {
    min-height: 100vh;
    background: ${color.bg};
    outline: none;
  }

  ::selection {
    background-color: ${color.altBlue};
    color: ${color.white};
    text-shadow: none;
  }

  br {
    visibility: hidden;
    color: transparent;
  }

  .fixed {
    position: fixed;
  }

  button {
    font-family: ${fontFamily};
    font-weight: 500;
  }
`;

export default GlobalStyles;
