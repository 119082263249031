/**
 * Scroll to a DOM element, and sets focus to the closest tabIndex
 **/
export function scrollToElement(
  /** Either the element id or a reference to the node */
  element: string | HTMLElement,
  behavior: "auto" | "smooth" | undefined,
  focus = true
) {
  const window = global && global.window;
  const node =
    typeof element === "string"
      ? document.getElementById(
          element.charAt(0) === "#" ? element.substr(1) : element
        )
      : element;

  if (node) {
    // Store the current scroll position, before we mess with the focus
    const y = window.scrollY;

    if (focus) {
      const focusNode: HTMLElement | null = node.closest("[tabindex]");
      if (focusNode) {
        // Set focus to the area we deeplinked to. This will move the scroll position
        focusNode.focus();
      }
      // Reset the scroll position after setting focus, so we can animate the transition
      window.scroll(0, y);
    }

    // Scroll the element into view
    node.scrollIntoView({
      // https://github.com/cypress-io/cypress/issues/3200
      behavior: (window as any).Cypress ? "auto" : behavior,
      block: "start",
    });
  } else {
    // Scroll the element to top if node is null
    window.scroll(0, 0);
  }
}
