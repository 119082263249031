import React from "react";
import styled from "styled-components";

import { captureMessage } from "@/utils/error-logging";
import { HelpButtonViewModel } from "@/view-models/HelpButtonViewModel";

import HelpIconButton from "../../../HelpIconButton/HelpIconButton";
type Props = {
  attributes: string;
};

export const StyledHelpLink = styled.span`
  display: block;

  margin-top: 15px;
`;

const HelpLink = ({ attributes }: Props) => {
  try {
    const help: HelpButtonViewModel = JSON.parse(attributes);

    return (
      <StyledHelpLink>
        <HelpIconButton help={help} marginLeft={false} variant={"inverse"} />
      </StyledHelpLink>
    );
  } catch (e) {
    // Invalid Json
    captureMessage(
      "Tried to render HelpLink with invalid JSON: " + attributes,
      e
    );
  }

  return null;
};

HelpLink.displayName = "HelpLink";

export default HelpLink;
