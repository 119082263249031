import * as React from "react";
import styled from "styled-components";

import Spinner from "@/components/Spinner/Spinner";
import { durations } from "@/styles/animations";
import { focusOutline } from "@/styles/style-helpers";
import { color } from "@/styles/theme";
import { TrackingTypes } from "@/types/TrackingTypes";
import { LinkViewModel } from "@/view-models/LinkViewModel";

import BaseButton from "./BaseButton";

export type ButtonVariation = "primary" | "neutral" | "text" | "background";

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = HTMLButtonProps &
  LinkViewModel & {
    children?: React.ReactNode;

    /** Always render as anchor tag, don't fall back to button */
    isAnchor?: boolean;
    disabled?: boolean;
    submitting?: boolean;
    icon?: React.ReactNode;

    /** Fill the container width */
    fillWidth?: boolean;
    type?: "button" | "submit";
    variation?: ButtonVariation;
    plain?: boolean;
    onClick?: () => void;

    longText?: boolean;
  };

type StyledButtonProps = ButtonProps & {
  hasIcon?: boolean;
};

const StyledIcon = styled.span<ButtonProps>`
  position: absolute;
  width: 50px;
  top: 0;
  left: 0;
  bottom: 0;
  flex: 0 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  visibility: ${(p) => p.submitting && "hidden"};
`;

/**
 * Create variations of the Button based on the Button Class
 **/
const StyledBase = styled(BaseButton)<StyledButtonProps>`
  ${(props) => props.fillWidth && "width: 100%;"};
  max-width: 100%;
  text-align: center;
  height: 51px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  padding: 16px 20px ${(props) => (props.hasIcon ? " 16px 70px" : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${/* sc-selector */ StyledIcon} {
    position: absolute;
    width: 50px;
    top: 0;
    bottom: 0;
  }
`;

const StyledInner = styled.span<ButtonProps>`
  opacity: ${(p) => (p.submitting ? 0 : undefined)};
  transition: opacity 0.2s;
`;

const StyledPrimaryButton = styled(StyledBase)<StyledButtonProps>`
  background: ${color.blue};
  color: ${color.white};
  ${focusOutline(color.blue)};
  transition: background ${durations.fast} ease-out;

  ${/* sc-selector */ StyledIcon} {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  &:hover {
    background: ${color.darkGrey};

    ${/* sc-selector */ StyledIcon} {
      svg {
        fill: ${color.altBlue};
        color: ${color.altBlue};
      }
    }
  }

  &:active {
    background: #4a4a69;
    transition: background ${durations.fast} ease-out;
  }
  &[disabled] {
    background: ${(p) => (p.submitting ? color.blue : color.disabled)};

    ${/* sc-selector */ StyledIcon} {
      svg {
        fill: ${(p) => !p.submitting && color.white};
        color: ${(p) => !p.submitting && color.white};
      }
    }
  }
`;

const StyledTextButton = styled(StyledBase)<StyledButtonProps>`
  color: ${color.blue};
  padding: 16px 20px ${(props) => (props.hasIcon ? " 16px 50px" : null)};
  ${focusOutline(color.blue)};

  &:hover,
  &:active {
    color: ${color.grey};
  }
`;

const StyledBackgroundButton = styled(StyledBase)<StyledButtonProps>`
  background-color: ${color.bg};

  &:hover,
  &:active {
    background-color: ${color.light};
  }
`;

const StyledNeutralButton = styled(StyledBase)<StyledButtonProps>`
  background: ${color.light};
  color: ${color.blue};

  ${/* sc-selector */ StyledIcon} {
    border-right: 1px solid rgba(209, 197, 195, 0.1);
  }
`;

function getButton(type?: ButtonVariation) {
  switch (type) {
    case "primary":
      return StyledPrimaryButton;
    case "text":
      return StyledTextButton;
    case "neutral":
      return StyledNeutralButton;
    case "background":
      return StyledBackgroundButton;
    default:
      return StyledPrimaryButton;
  }
}

const Button = ({
  children,
  label,
  icon,
  type,
  variation,
  fillWidth,
  disabled,
  submitting,
  plain,
  isAnchor,
  ...rest
}: ButtonProps) => {
  const ButtonVariation = getButton(variation);

  const tracking: TrackingTypes = {
    section: "cta",
    eventName: "ctaClicks",
    name: (typeof children === "string" && children) || label,
  };

  return (
    <ButtonVariation
      aria-busy={submitting}
      aria-label={
        submitting && (typeof children === "string" || label)
          ? (children as string) || label
          : undefined
      }
      disabled={submitting || disabled}
      fillWidth={fillWidth}
      hasIcon={!!icon}
      submitting={submitting}
      tracking={tracking}
      type={type}
      {...rest}
    >
      {icon && <StyledIcon submitting={submitting}>{icon}</StyledIcon>}

      {plain ? (
        children || label
      ) : (
        <StyledInner submitting={submitting}>{children || label}</StyledInner>
      )}

      {submitting ? <Spinner instant /> : null}
    </ButtonVariation>
  );
};

Button.displayName = "Button";
Button.defaultProps = {
  type: "button",
};

export default Button;
