import { captureFetchError } from "./error-logging";

const defaultGetHeaders = {};
const defaultPostHeaders = {
  "Content-Type": "application/x-www-form-urlencoded",
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {Response} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
export async function parseJSON(response: Response): Promise<{
  [key: string]: any;
}> {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json().catch((error) => {
      // If we fail to parse the JSON log it and return empty object
      captureFetchError(error, response);

      return {};
    });
  } else {
    return Promise.resolve({});
  }
}

function redirect(body: any) {
  if (body && typeof body.redirect === "string") {
    // If the response has a redirect key, update the current browser URL
    global.location.assign(body.redirect);
  }

  return body;
}

async function handleError(response: Response, request: Request) {
  const url = global.URL
    ? new global.URL(response.url)
    : { pathname: response.url };
  const body = await parseJSON(response);
  const error = new Error(body.message || response.statusText);

  switch (response.status) {
    case 400:
    case 401:
    case 403:
      // Forbidden
      // Expected errors, so don't log as error
      break;
    default:
      captureFetchError(
        new Error(
          `${response.status}: ${response.statusText} (${url.pathname})`
        ),
        response,
        request,
        { body }
      );
      break;
  }

  // Perform a redirect if necessary
  redirect(body);

  return Promise.reject({
    message: body.message || error.message,
    error,
    response,
    request,
    body,
  });
}

/**
 * Checks if a network request came back fine, and throws an error if not
 */
export function checkStatus(response: Response, request: Request) {
  if (response.ok) return response;

  return handleError(response, request);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {RequestOptions} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request(
  url: string,
  options: any = {}
): Promise<{
  [key: string]: any;
}> {
  const request = {
    method: options.method || "GET",

    /* By default, we set the HelpPanelContent-Type for POST requests */
    headers: options.method === "POST" ? defaultPostHeaders : defaultGetHeaders,
    ...options,
  };

  return fetch(url, request)
    .then((response: Response) => checkStatus(response, request))
    .then(parseJSON)
    .then(redirect);
}
