import useMedia from "@charlietango/use-media";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";

import { ArticleContext } from "@/components/ArticleContext/ArticleContext";
import Image from "@/components/Image/Image";
import { blockMargin } from "@/components/Richtext/Richtext";
import { breakpoints, mediaOnly } from "@/styles/media";

import { AreaConsumer } from "../../../AreaContext/AreaContext";

type Props = {
  src: string;
  title?: string;
  alt?: string;
  margin?: blockMargin;
  imageColumnSpan?: number;
  attributes?: string;
};

type InternalProps = {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  imageColumnSpan?: number;
};

const StyledWrapper = styled.span<{ margin?: string; maxWidth?: number }>`
  display: block;
  margin: ${(p) => {
      if (p.margin === "normal") return "40px";
      if (p.margin === "small") return "30px";

      return "80px";
    }}
    0;
  max-width: ${(p) => (p.maxWidth ? p.maxWidth + "px" : undefined)};

  ${mediaOnly.xs`
   margin: ${(p) => {
     if (p.margin === "normal") return "20px";

     return "40px";
   }} 0px;
  `};
`;

const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const imageSizes = {
  default: {
    maxWidth: "1440px",
  },
  accordion: {
    maxWidth: "550px",
  },
  article: (
    isMobile: boolean,
    articleWidth: number,
    imageColumnSpan?: number
  ) => {
    let maxWidth = articleWidth;

    //consider margin that exists so image doens't overflow
    if (
      articleWidth > breakpoints.xs * 16 &&
      articleWidth <= breakpoints.sm * 16
    )
      articleWidth -= 40;
    else if (
      articleWidth > breakpoints.sm * 16 &&
      articleWidth <= breakpoints.md * 16
    )
      articleWidth -= 20;

    if (imageColumnSpan) {
      if (isMobile)
        switch (imageColumnSpan) {
          case 1:
            maxWidth = articleWidth * 0.4;
            break;
          case 2:
            maxWidth = articleWidth * 0.6;
            break;
          default:
            maxWidth = articleWidth;
            break;
        }
      //since there is 5 imagecolumns we want to set the widht of the image to 20%, 40% etc...
      else maxWidth = articleWidth * imageColumnSpan * 0.2;
    }

    return {
      maxWidth: maxWidth + "px",
    };
  },
};

const ImageRenderer = ({
  src,
  alt,
  margin,
  imageColumnSpan,
  attributes,
}: Props) => {
  const isMobileView = !useMedia(`(min-width: ${breakpoints.sm}em)`);
  const articleWidth = useContext(ArticleContext);

  if (!src && !attributes) return null;

  let propsIn: InternalProps | undefined = undefined;

  if (attributes && isValidJSON(attributes)) {
    propsIn = JSON.parse(attributes);

    if (propsIn) {
      src = propsIn.src;
      alt = propsIn.alt;
      imageColumnSpan = propsIn.imageColumnSpan;
    }
  }

  const [imgSrc, attr] = src.split("?", 2);
  const attrs = new URLSearchParams(attr);
  const width = parseInt(attrs.get("width") || "0", 10);
  const height = parseInt(attrs.get("height") || "0", 10);

  return (
    <AreaConsumer>
      {(area) => (
        <StyledWrapper
          margin={margin}
          maxWidth={
            area === "accordion"
              ? (width > 0 && Math.min(550, width)) || 0
              : area === "article"
              ? 880
              : Math.max(width, 0)
          }
        >
          <Image
            alt={alt}
            height={height}
            lazy={process.env.NODE_ENV !== "test"}
            ratio="original"
            sizes={
              (area === "article" &&
                imageSizes.article(
                  isMobileView,
                  articleWidth,
                  imageColumnSpan
                )) ||
              imageSizes[area] ||
              imageSizes.default
            }
            src={imgSrc}
            width={width}
          />
        </StyledWrapper>
      )}
    </AreaConsumer>
  );
};

ImageRenderer.displayName = "ImageRenderer";

export default ImageRenderer;
