import React from "react";

type Props = {
  className?: string;
  flip?: boolean;
};

export default ({ flip, ...props }: Props) => (
  <svg height="14" width="8" {...props}>
    <path
      d={
        flip
          ? "M6.5 12.6569L1.86822 7.00001L6.5 1.34315"
          : "M1.5 1.34314L6.13178 6.99999L1.5 12.6568"
      }
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
