import * as React from "react";
import styled from "styled-components";

import { LinkViewModel } from "@/view-models/LinkViewModel";

import image from "./previewModeBadge.png";

type Props = {
  currentUrl: string;
  link: LinkViewModel;
};

const StyledBadge = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  width: 120px;
  height: 120px;
  background: url(${image}) no-repeat;
  background-size: 120px 120px;
  z-index: 3;
`;

const PreviewBadge = ({ link, currentUrl }: Props) => {
  if (!link || !link.href) return null;

  return (
    <StyledBadge href={`${link.href}?redir=${currentUrl}`} title={link.label} />
  );
};

PreviewBadge.displayName = "PreviewBadge";
PreviewBadge.defaultProps = {
  currentUrl: "",
};

export default PreviewBadge;
