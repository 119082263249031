import { rem } from "polished";
import * as React from "react";
import styled from "styled-components";

import { durations } from "@/styles/animations";
import { mediaOnly, mediaQuery } from "@/styles/media";
import { color } from "@/styles/theme";

type StyledProps = {
  bgColor?: string;
  left?: string;
  marginTop?: string;
  marginBottom?: string;
};

type Props = {
  children?: React.ReactNode;
  className?: string;
  showDot?: boolean;
  coloredBackground?: boolean;
};

export const StyledCardDot = styled.div.attrs({
  "data-testid": "card-dot",
})`
  background-color: ${(p: StyledProps) => p.bgColor || color.blue};
  transition: background-color ${durations.fast} ease-in;
  position: absolute;
  left: ${(p: StyledProps) => p.left || rem(30)};
  height: 16px;
  width: 60px;
  top: 0;
  ${mediaOnly.xs`
    left: ${(p: StyledProps) => p.left || rem(20)};
    height: 12px;
  `};
`;

export const StyledCardContainer = styled.article`
  display: grid;
  position: relative;
  width: 100%;
  background: ${(p: StyledProps) => p.bgColor || color.white};
  padding: ${(p: StyledProps) => rem(p.marginTop || 40)} ${rem(20)}
    ${(p: StyledProps) => p.marginBottom || rem(40)};
  ${mediaQuery.sm`
    padding-left: ${rem(30)};
    padding-right: ${rem(30)};
  `};
  ${mediaOnly.xs`
    padding: 30px 20px;
  `};
`;

function Card({
  coloredBackground = false,
  children,
  className,
  showDot = true,
}: Props) {
  return (
    <StyledCardContainer
      bgColor={coloredBackground ? color.paleLilac : color.white}
      className={className}
    >
      {showDot && <StyledCardDot />}
      {children}
    </StyledCardContainer>
  );
}

Card.displayName = "Card";
Card.defaultProps = {};

export default Card;
