import { breakpoints, DIMENSION_NAMES } from "@/styles/media";
import invariant from "@/utils/invariant";

/**
 * ⚠️ Make sure to keep widths and ratios synced with src/view-models/cs/ImageSizesWidthTypes.cs ⚠️
 * The ViewModel is only used by .NET, but it should still contain the known values
 **/
export const IMAGE_WIDTHS = [
  330,
  375,
  440,
  660,
  750,
  880,
  1024,
  1320,
  1440,
  1720,
  1920,
  2048,
  2880,
];
const MAX_DPR = 3;

// If image breaks are undefined, make sure the width tops out 100 viewport width
const defaultBreakpoints = {
  hd: "100vw",
};

export type ImageRatio = "original" | "16_9" | "3_2";

/** Max image width at a given breakpoint. */
export type ImageBreakpoints = {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  hd?: string;
};

export type ImageSizes = {
  breakpoints?: ImageBreakpoints;
  maxWidth: string | number;
};

/**
 * Converts a string number with a known unit type, to a unitless number in px size
 **/
export function sizeToNumber(size: string | number): number {
  if (!size) return 0;
  if (typeof size === "number") return size;
  if (size.endsWith("px")) return parseInt(size.substr(0, size.length - 2), 10);
  if (size.endsWith("em"))
    return parseInt(size.substr(0, size.length - 2), 10) * 16;
  if (size.endsWith("vw"))
    return (parseInt(size.substr(0, size.length - 2), 10) * 1440) / 100;
  if (size.endsWith("rem"))
    return parseInt(size.substr(0, size.length - 3), 10) * 16;

  return parseInt(size, 10);
}

/**
 * Generate sizes for images, while respecting Breakpoints
 **/
export function generateSizes(
  imageBreaks: ImageBreakpoints = defaultBreakpoints,
  maxWidth: string | number = "1440px"
) {
  const queries = DIMENSION_NAMES.reduce((acc, breakpoint, index) => {
    // If the breakpoint is 0, or undefined, take the next dimension
    const maxWidth =
      breakpoints[breakpoint] || breakpoints[DIMENSION_NAMES[index + 1]];
    const breakpointSize = imageBreaks[breakpoint];

    if (breakpointSize) {
      return `${acc}(max-width: ${maxWidth}em) ${breakpointSize}, `;
    }

    return acc;
  }, "");

  return queries + (typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth);
}

export function generateSrc(
  url: string,
  width: number,
  ratio: ImageRatio = "original"
) {
  if (process.env.DEV) {
    invariant(
      IMAGE_WIDTHS.includes(width),
      `The requested image width of ${width}px is not a known size.`
    );
  }

  if (url && !url.includes("static/")) {
    const pos = url.lastIndexOf("/");

    return `${url.substr(0, pos)}/${ratio}/${width}/${url.substr(pos + 1)}`;
  }

  return `${url}?w=${width}&ratio=${ratio || ""}`;
}

/**
 * Take an image URL and generate the "srcset" values for all the predefined widths
 **/
export function generateSrcSet(
  baseUrl: string,
  ratio: ImageRatio,
  maxElementWidth: string | number,
  maxImageWidth?: number
) {
  const elWidth = sizeToNumber(maxElementWidth) * MAX_DPR;
  const maxWidth = maxImageWidth ? Math.min(elWidth, maxImageWidth) : elWidth;
  const widths = maxWidth
    ? IMAGE_WIDTHS.filter((w, i) => w <= maxWidth || i === 0)
    : IMAGE_WIDTHS;

  // Image is located on CDN, transform it.
  return widths
    .map((width) => `${generateSrc(baseUrl, width, ratio)} ${width}w`)
    .join(",");
}

/**
 * Generate the actual ratio for the the known Image ratios
 **/
export function calculateRatio(width = 3, height = 2, aspect?: ImageRatio) {
  switch (aspect) {
    case "3_2":
      return 2 / 3;
    case "16_9":
      return 9 / 16;
    default:
      return height / width;
  }
}
