import * as React from "react";

import { AreaContext } from "@/components/AreaContext/AreaContext";
import Heading from "@/components/Heading/Heading";
import { blockMargin } from "@/components/Richtext/Richtext";

type Props = {
  children?: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  marginTop: blockMargin;
  maxWidth?: boolean;
};

function HeaderRenderer({
  children,
  level,
  marginTop,
  maxWidth = false,
}: Props) {
  const area = React.useContext(AreaContext);

  if (level === 1) {
    return (
      <Heading
        isArticle={area === "article"}
        level={3}
        marginTop={marginTop}
        maxWidth={maxWidth}
        tag="h2"
      >
        {children}
      </Heading>
    );
  }
  if (level === 2) {
    return (
      <Heading
        isArticle={area === "article"}
        level={4}
        marginBottom="normal"
        marginTop={marginTop}
        maxWidth={maxWidth}
        tag="h2"
      >
        {children}
      </Heading>
    );
  }

  return (
    <Heading
      isArticle={area === "article"}
      level={5}
      marginTop={marginTop}
      maxWidth={maxWidth}
      tag="h3"
    >
      {children}
    </Heading>
  );
}

HeaderRenderer.displayName = "HeaderRenderer";
HeaderRenderer.defaultProps = {
  marginTop: "large",
};

export default HeaderRenderer;
