const supports: { objectFit?: boolean } = { objectFit: undefined };

/**
 * Detect if objectFit is supported on the client
 * @type {boolean}
 */
export function objectFitSupport() {
  if (process.env.NODE_ENV === "test") {
    supports.objectFit = true;
  } else if (supports.objectFit === undefined) {
    supports.objectFit = global.document
      ? global.document.body.style.objectFit !== undefined
      : true;
  }

  return supports.objectFit;
}

export default {
  objectFitSupport,
};
