import { css } from "styled-components";

export type DimensionNames = "xsm" | "xs" | "sm" | "md" | "lg" | "hd";
export const DIMENSION_NAMES = ["xsm", "xs", "sm", "md", "lg", "hd"];

type MediaQuery = {
  xs: (...args: Array<any>) => any;
  xsm: (...args: Array<any>) => any;
  sm: (...args: Array<any>) => any;
  md: (...args: Array<any>) => any;
  lg: (...args: Array<any>) => any;
  hd: (...args: Array<any>) => any;
};

const breakpoints = {
  xs: 0,
  xsm: 37.5, // 37*16 = 600px
  sm: 48, // 48*16 = 768px
  md: 64, // 64*16 = 1024px
  lg: 75, // 75*16 = 1200px
  hd: 88, // 88*16 = 1408px
};

const pxBreakpoints = {
  sm: breakpoints.sm * 16,
  xsm: breakpoints.xsm * 16,
  md: breakpoints.md * 16,
  lg: breakpoints.lg * 16,
  hd: breakpoints.hd * 16,
};

function makeMedia(media: string) {
  return (...args: any) => {
    return css`
      @media ${media} {
        ${
          // @ts-ignore typescript tripping up here seems like a bug
          css(...args)
        };
      }
    `;
  };
}

// Generate media queries that target the query and up
const mediaQuery: MediaQuery = DIMENSION_NAMES.reduce((acc, breakpoint) => {
  const breakpointWidth = breakpoints[breakpoint];
  acc[breakpoint] = makeMedia(
    [
      "only screen",
      breakpointWidth !== 0 && `(min-width: ${breakpointWidth}em)`,
    ]
      .filter(Boolean)
      .join(" and ")
  );

  return acc;
}, {} as MediaQuery);

// Generate media quertes that target the query and down
const mediaQueryDown: MediaQuery = DIMENSION_NAMES.reduce((acc, breakpoint) => {
  const breakpointWidth = breakpoints[breakpoint];
  acc[breakpoint] = makeMedia(
    [
      "only screen",
      breakpointWidth !== 0 && `(max-width: ${breakpointWidth}em)`,
    ]
      .filter(Boolean)
      .join(" and ")
  );

  return acc;
}, {} as MediaQuery);

// Generate media queries that only targets the specific query
const mediaOnly: MediaQuery = DIMENSION_NAMES.reduce(
  (acc, breakpoint, index) => {
    const breakpointWidth = breakpoints[breakpoint];
    const nextBreakpoint =
      index < DIMENSION_NAMES.length
        ? DIMENSION_NAMES[index + 1] ?? null
        : null;

    acc[breakpoint] = makeMedia(
      [
        "only screen",
        breakpointWidth !== 0 && `(min-width: ${breakpointWidth}em)`,
        nextBreakpoint &&
          `(max-width: ${(breakpoints[nextBreakpoint] * 16 - 1) / 16}em)`,
      ]
        .filter(Boolean)
        .join(" and ")
    );

    return acc;
  },
  {} as MediaQuery
);

export { breakpoints, mediaOnly, mediaQuery, mediaQueryDown, pxBreakpoints };
