/* eslint-disable global-require, no-underscore-dangle */
// It's important that we import 'core-js' before everything else, including React.
// Importing it later will break React in IE11
import "core-js/stable";
import "regenerator-runtime/runtime";
import "focus-visible";

import { Provider } from "jotai";
import * as React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";

import { configAtom, metaAtom } from "@/store";

import App from "./App/App";
import { ModuleInstance } from "./App/app-modules/modules-utils";
import { preloadModules } from "./App/app-modules/modules-utils";
import { ROOT_ID } from "./App/constants";
import ErrorBoundary from "./App/ErrorBoundary";
import Router from "./App/Router";
import Apollo from "./graphql/Apollo";
import UserSession from "./graphql/UserSession";
import { initI18n } from "./i18n";
import {
  captureException,
  captureMessage,
  init as errorLoggingInit,
} from "./utils/error-logging";
import loadPolyfills from "./utils/polyfills";
import { EndpointsViewModel } from "./view-models/EndpointsViewModel";
import { HtmlConfigViewModel } from "./view-models/HtmlConfigViewModel";
import { HtmlViewModel } from "./view-models/HtmlViewModel";

/**
 * Initialize the client code.
 */
export default function init(data: HtmlViewModel) {
  // Initial data is JSON string, so let's parse it now.
  if (module.hot) {
    // Cache the data for hot reloading
    global.__HOT_PAGE_DATA = data;
  }
  const config: HtmlConfigViewModel = window.__CONFIG || {};
  if (
    process.env.NODE_ENV === "production" &&
    config.endpoints &&
    config.endpoints.errorLoggingURI
  ) {
    errorLoggingInit(config?.endpoints?.errorLoggingURI);
  }

  // Preload polyfills and modules before rendering the app
  return Promise.all([preloadModules(data.modules), loadPolyfills()]).then(
    ([moduleInstances]) => {
      renderApp(moduleInstances, data, config);
      debugLogging();
    }
  );
}

function renderApp(
  modules: Array<ModuleInstance>,
  { meta }: HtmlViewModel,
  config: HtmlConfigViewModel
) {
  const root = document.getElementById(ROOT_ID);

  if (root) {
    // Fetch the initial config, so we can instantiate the site
    const lang = meta && meta.locale ? meta.locale.substr(0, 2) : "da";
    const endpoints: EndpointsViewModel | Record<string, never> =
      config.endpoints || {};
    global.trackingEnabled = config.trackingEnabled;

    if (process.env.NODE_ENV === "development") {
      console.log(
        `%cAPI: %c${config.endpoints ? config.endpoints.graphql : "undefined"}`,
        "font-weight: 300; color: #747589",
        "font-weight: 700; color: #14143C"
      );
    }

    const app = (
      <Provider
        initialValues={[
          [configAtom, config],
          [metaAtom, meta],
        ]}
      >
        <I18nextProvider i18n={initI18n(lang, config.i18n)}>
          <ErrorBoundary debug={config.debug} module="App">
            <Router>
              <Apollo debug={config.debug} meta={meta} uri={endpoints.graphql}>
                {config.auth ? (
                  <UserSession
                    auth={config.auth}
                    initialModules={modules}
                    meta={meta}
                  >
                    <App config={config} initialModules={modules} />
                  </UserSession>
                ) : (
                  <App config={config} initialModules={modules} />
                )}
              </Apollo>
            </Router>
          </ErrorBoundary>
        </I18nextProvider>
      </Provider>
    );

    // If developing over the proxy, use render to overwrite to server output since it won't match
    if (!!process.env.PROXY) {
      ReactDOM.render(app, root);
    } else {
      ReactDOM.hydrate(app, root);
    }
  } else {
    throw new Error("Failed to find #" + ROOT_ID);
  }
}

function debugLogging() {
  if (window.location.search) {
    // Debug logging methods, to trigger messages and exceptions by providing query params
    const params = new URLSearchParams(window.location.search);
    if (params.has("logging")) {
      if (params.has("message") || params.get("logging") === "message") {
        captureMessage(params.get("message") || "invoked_message_via_query");
      } else {
        captureException(
          new Error(params.get("exception") || "invoked_exception_via_query")
        );
      }
    }
  }
}

if (module.hot) {
  // If modules are updated, we need to preload the new modules before rendering
  module.hot.accept("./App/app-modules/modules-utils", () => {
    // Fetch the current modules, and reinit the app
    const data: HtmlViewModel = global.__HOT_PAGE_DATA;
    const config: HtmlConfigViewModel = window.__CONFIG || {};
    preloadModules(data.modules).then((modules) => {
      renderApp(modules, data, config);
    });
  });
}

/* istanbul ignore next */
if (process.env.NODE_ENV !== "test" && !global.VERSION) {
  // Init the client now - Moved inside init function to make it testable :)
  global.VERSION = process.env.VERSION;

  init(global.__INITIAL);

  if (process.env.NODE_ENV === "production") {
    console.log(
      `%cVurderingsportalen`,
      "font-weight: 700; font-size:20px; color: #14143C"
    );
    console.log(`%c${global.VERSION}`, "font-weight: 700; color: #FF6C0C");
  }
}
