import * as React from "react";
import styled from "styled-components";
import { Diff } from "utility-types";

import CtaLink from "@/components/CtaLink/CtaLink";
import Link from "@/components/Link/Link";
import { color } from "@/styles/theme";
import { TrackingTypes } from "@/types/TrackingTypes";
import generateTrackingAttributes from "@/utils/generateTrackingAttributes";

type Props = {
  attributes: string;
  isAccordion?: boolean;
};

const StyledUnderlineLink = styled(Link)`
  && {
    text-decoration: underline;

    &:hover {
      color: ${color.darkGrey};
    }
  }
`;

const StyledCustomCtaLink = styled(CtaLink)`
  display: block !important;
`;

const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const pattern = /""([^"]*)""/g;
const fixJSON = (str) => str.replace(pattern, '"\\"$1\\""');

type InternalProps = {
  text: string;
  href: string;
  title?: string;
  external?: boolean;
  type?: string;
};

type ArrowLinkProps = Diff<InternalProps, { text: string; type?: string }> & {
  children?: React.ReactNode;
};

function ArrowLink(props: ArrowLinkProps) {
  return (
    <StyledCustomCtaLink {...props} iconPosition={"right"}>
      {props.children}
    </StyledCustomCtaLink>
  );
}

function Anchorlink({ attributes, isAccordion }: Props) {
  const isValid = isValidJSON(attributes);
  const propsIn = !isValid ? fixJSON(attributes) : attributes;

  try {
    const { title, href, external, text, type }: InternalProps =
      JSON.parse(propsIn);

    const tracking: TrackingTypes = {
      section: isAccordion ? "accordion" : "article",
      name: text,
      eventName: isAccordion ? "accordionLinkClick" : "articleLinkClick",
    };

    const Component =
      type === "arrowLinkRight" ? ArrowLink : StyledUnderlineLink;

    return (
      <Component
        external={external}
        href={href}
        target={external ? "_blank" : "_self"}
        title={title}
        {...generateTrackingAttributes(tracking)}
      >
        {text}
      </Component>
    );
  } catch (e) {
    console.error(e);

    return null;
  }
}

Anchorlink.displayName = "Anchorlink";

export default Anchorlink;
