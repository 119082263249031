import { TrackingTypes } from "@/types/TrackingTypes";
import { HtmlConfigViewModel } from "@/view-models/HtmlConfigViewModel";
import { LinkViewModel } from "@/view-models/LinkViewModel";
import { MetaViewModel } from "@/view-models/MetaViewModel";

import { ModuleInstance } from "../app-modules/modules-utils";

export const DATA_TRACKING_SECTION = "data-tracking-section";
export const DATA_TRACKING_TYPE = "data-tracking-type";
export const DATA_TRACKING_NAME = "data-tracking-name";
export const DATA_TRACKING_EVENT_NAME = "data-tracking-event-name";
export const DATA_TRACKING_TITLE = "data-tracking-title";
export const DATA_TRACKING_SUB_TITLE = "data-tracking-sub-title";
export const DATA_TRACKING_LINK_TITLE = "data-tracking-link-title";
export const DATA_TRACKING_LEVEL = "data-tracking-level";
export const DATA_TRACKING_FILE_NAME = "data-tracking-file-name";
export const DATA_TRACKING_FILE_URL = "data-tracking-file-url";
export const DATA_TRACKING_FILE_TYPE = "data-tracking-file-type";

export const NOT_DEFINED_LABEL = "ikke defineret";

const isAbsoluteURL = (url) => {
  const pattern = /^http(s?):[/]{2}(?!\/).*$/gi;

  return !!url && pattern.test(url);
};

// create breacrumb tracking for each siteSection in tracking object
function createBreadcrumbPath(
  currentIndex: number,
  breadCrumbs: Array<LinkViewModel>
) {
  return breadCrumbs.reduce((prev, next, idx) => {
    if (idx < currentIndex) {
      return prev ? `${prev}:${next?.label || ""}` : next?.label || "";
    } else {
      return prev || "";
    }
  }, "");
}

export function PageTracking(
  meta: MetaViewModel,
  config: HtmlConfigViewModel,
  modules: Array<ModuleInstance>
) {
  const breadcrumbs = modules.find((module) => module.name === "Breadcrumbs");
  const errorPage = modules.find((module) => module.name === "ErrorPage");
  // extract ejerbolig, erhverv, or skov og landsbrug:
  const pathname =
    meta && isAbsoluteURL(meta.currentArea)
      ? new URL(meta.currentArea || "").pathname.substr(1).toLowerCase()
      : "";
  const profile = pathname.length > 0 ? pathname : NOT_DEFINED_LABEL;
  const domain =
    meta && isAbsoluteURL(meta.absoluteUrl)
      ? new URL(meta.absoluteUrl || "").hostname || NOT_DEFINED_LABEL
      : NOT_DEFINED_LABEL;
  if (config.trackingEnabled && errorPage) {
    window.digitalData = {
      pageInfo: {
        pageName: "error page",
        profile,
        contentType: "errorPage",
        language: (meta.locale || NOT_DEFINED_LABEL).toLowerCase(),
        siteSectionLevel: 1,
        siteSection1: "error",
        siteSection2: "error",
        siteSection3: "error",
        siteSection4: "error",
        siteSection5: "error",
        domain,
      },
    };
  } else if (
    config.trackingEnabled &&
    breadcrumbs &&
    breadcrumbs.props.links.length > 0
  ) {
    const sectionLevel = breadcrumbs.props.links.length;
    window.digitalData = {
      pageInfo: {
        pageName: (meta.title || NOT_DEFINED_LABEL).toLowerCase(),
        profile,
        language: (meta.locale || NOT_DEFINED_LABEL).toLowerCase(),
        siteSectionLevel: sectionLevel,
        domain,
      },
    };

    // loop over breadcrumb and construct data for tracking
    for (let index = 1; index < 6; index++) {
      window.digitalData.pageInfo = {
        ...window.digitalData.pageInfo,
        [`siteSection${index}`]: createBreadcrumbPath(
          index,
          breadcrumbs.props.links
        ).toLowerCase(),
      };
    }
  } else if (config.trackingEnabled) {
    window.digitalData = {
      pageInfo: {
        pageName: (meta.title || NOT_DEFINED_LABEL).toLowerCase(),
        profile,
        language: (meta.locale || NOT_DEFINED_LABEL).toLowerCase(),
        siteSectionLevel: 1,
        siteSection1: `${profile || NOT_DEFINED_LABEL}:${
          meta.title || NOT_DEFINED_LABEL
        }`.toLowerCase(),
        siteSection2: `${profile || NOT_DEFINED_LABEL}:${
          meta.title || NOT_DEFINED_LABEL
        }`.toLowerCase(),
        siteSection3: `${profile || NOT_DEFINED_LABEL}:${
          meta.title || NOT_DEFINED_LABEL
        }`.toLowerCase(),
        siteSection4: `${profile || NOT_DEFINED_LABEL}:${
          meta.title || NOT_DEFINED_LABEL
        }`.toLowerCase(),
        siteSection5: `${profile || NOT_DEFINED_LABEL}:${
          meta.title || NOT_DEFINED_LABEL
        }`.toLowerCase(),
        domain,
      },
    };
  }

  window &&
    window._satellite &&
    window._satellite.track &&
    window._satellite.track("pageView");
}

export function generateTrackingAttributes(
  tracking: TrackingTypes | typeof undefined
) {
  if (!tracking) return undefined;

  return {
    [DATA_TRACKING_SECTION]: tracking.section,
    [DATA_TRACKING_TYPE]: tracking.type,
    [DATA_TRACKING_NAME]: tracking.name,
    [DATA_TRACKING_EVENT_NAME]: tracking.eventName,
    [DATA_TRACKING_TITLE]: tracking.title,
    [DATA_TRACKING_SUB_TITLE]: tracking.subTitle,
    [DATA_TRACKING_LINK_TITLE]: tracking.linkTitle,
  };
}
