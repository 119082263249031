import { ContactAndObjectionFormErrorMessagesViewModel } from "@/view-models/ContactAndObjectionFormErrorMessagesViewModel";

import { EditorFormProps } from "../../types";

// check valid dates in following format: dd-mm-yyyy (leap years included)
export const dateRegex = new RegExp(
  /^(?:(?:31(-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(-)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
);

type Errors = {
  textField?: string;
  textFieldWithLimit?: string;
  dateField?: string;
  checkBoxField?: string;
  attachFileField?: string;
};

export default (
  values: Record<string, any>,
  editorProps: EditorFormProps,
  errorMessages: ContactAndObjectionFormErrorMessagesViewModel
): Errors => {
  const {
    textFieldWithLimitProps,
    textFieldProps,
    dateFieldProps,
    attachFileFieldProps,
    checkBoxFieldProps,
  } = editorProps;

  const {
    textFieldRequired,
    textFieldWithLimitRequired,
    dateFieldRequired,
    dateFieldWrongPattern,
    checkboxFieldRequired,
    attachFileFieldRequired,
  } = errorMessages;

  const errors: Errors = {};

  if (textFieldProps?.isMandatory && !values.textField) {
    errors.textField = textFieldRequired;
  }
  if (textFieldWithLimitProps?.isMandatory && !values.textFieldWithLimit) {
    errors.textFieldWithLimit = textFieldWithLimitRequired;
  }
  if (dateFieldProps?.isMandatory && !values.dateField) {
    errors.dateField = dateFieldRequired;
  }
  if (dateFieldProps && values.dateField && !dateRegex.test(values.dateField)) {
    errors.dateField = dateFieldWrongPattern;
  }
  if (checkBoxFieldProps?.isMandatory && values.checkBoxField?.length === 0) {
    errors.checkBoxField = checkboxFieldRequired;
  }
  if (values.attachFileField?.uploading) {
    errors.attachFileField = "uploading";
  }
  if (
    attachFileFieldProps?.isMandatory &&
    !values.attachFileField?.uploading &&
    values.attachFileField?.files?.length === 0
  ) {
    errors.attachFileField = attachFileFieldRequired;
  }

  return errors;
};
