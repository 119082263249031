import * as React from "react";

import { color } from "../styles/theme";

type Props = {
  width?: string;
  height?: string;
};

export default (props: Props) => (
  <svg
    aria-hidden="true"
    data-testid="exclamation-icon"
    height="16"
    width="6"
    {...props}
  >
    <path
      d="M2.983 10.519c.979 0 1.722.761 1.722 1.722 0 .96-.743 1.721-1.722 1.721a1.706 1.706 0 0 1-1.703-1.721c0-.961.76-1.722 1.703-1.722zM.515 1.776h4.97L3.855 8.9H2.13z"
      fill={color.altBlue}
      fillRule="evenodd"
    />
  </svg>
);
