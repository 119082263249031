import * as Sentry from "@sentry/browser";
import { Event, Status } from "@sentry/browser";
import { supportsReferrerPolicy } from "@sentry/utils";

const NAME = process.env.NAME || "vurderings-portalen";
const VERSION = "v" + (process.env.VERSION || "");
const RELEASE = NAME + VERSION;

/**
 * Initializes Sentry
 * Import and initialize passing an endpoint (where to send the logs)
 * Use Sentry.captureException(error) to intercept errors
 * ideally within componentDidCatch method of the ErrorBoundary component
 */
export const init = (endpoint?: string) => {
  if (!endpoint) {
    console.warn("Error logging requires a valid endpoint.");
  } else {
    Sentry.init({
      dsn: "https://000@skat.dk/1", // anything will work here if formatted as [STRING]@DOMAIN.NAMESPACE/[NUMBER]
      debug: false,
      environment: process.env.NODE_ENV,
      release: RELEASE,
      transport: transport(endpoint),
      integrations(integrations) {
        integrations = integrations.filter(
          (integration) => integration.name !== "Breadcrumbs"
        );

        return integrations;
      },
    });
  }
};

/**
 * Returns a new custom Transport
 * Transports specify how to send logs to the endpoint
 */
export function transport(endpoint: string): any {
  return class CustomTransport extends Sentry.Transports.BaseTransport {
    async sendEvent(
      event: Event
    ): Promise<{
      status: Status;
      event?: Event;
      reason?: string;
    }> {
      return fetch(endpoint, {
        method: "post",
        referrerPolicy: supportsReferrerPolicy() ? "origin" : "",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...event,
          timestamp: Date.now(),
          project: {
            name: NAME,
            version: VERSION,
            release: RELEASE,
          },
        }),
      }).then((response) => {
        return { status: Status.fromHttpCode(response.status) };
      });
    }
  };
}

export function captureFetchError(
  error: Error,
  response: {
    [key: string]: any;
  } = {},
  request: {
    [key: string]: any;
  } = {},
  extra:
    | {
        [key: string]: any;
      }
    | null
    | undefined = null
) {
  if (error && error.message) {
    Sentry.configureScope((scope) => {
      scope.setTag("type", "fetch");
      scope.setTag("url", response ? response.url : "");
      scope.setTag("page", global.location ? global.location.pathname : "");
      scope.setExtra("headers", response ? response.headers : "");

      scope.setExtra("request", request);
      if (extra) scope.setExtra("extra", extra);
      Sentry.captureMessage(error.message);
    });

    console.log(error.message);
  }
}

export function captureException(
  error: Error,
  extra:
    | {
        [key: string]: any;
      }
    | null
    | undefined = null
) {
  Sentry.withScope((scope) => {
    if (extra) scope.setExtras(extra);
    scope.setTag(
      "errorTag",
      global?.__HOT_PAGE_DATA?.meta?.errorTag || "Default"
    );
    Sentry.captureException(error);
  });

  console.error(
    "%c[capture-exception]",
    "font-weight: 700;color: #FF6C0C;",
    error.stack,
    extra || ""
  );
}

export function captureMessage(
  message: string,
  extra:
    | {
        [key: string]: any;
      }
    | null
    | undefined = null,
  level = Sentry.Severity.Error,
  errorTag?: string
) {
  Sentry.withScope((scope) => {
    if (extra) scope.setExtras(extra);
    // if error tag is set by captureMessage call
    errorTag
      ? scope.setTag("errorTag", errorTag)
      : scope.setTag(
          "errorTag",
          global?.__HOT_PAGE_DATA?.meta?.errorTag || "Default"
        );
    Sentry.captureMessage(message, level);
  });
  console.log(
    "%c[capture-message]",
    "font-weight: 700;color: #FF6C0C;",
    message,
    extra || ""
  );
}
