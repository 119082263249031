import * as React from "react";
import styled from "styled-components";

import Spinner from "../Spinner/Spinner";

type Props = {
  className?: string;
};

const StyledContainer = styled.div`
  position: relative;
  height: 40vh;
  max-height: 500px;
`;

function Placeholder(props: Props) {
  return (
    <StyledContainer {...props}>
      <Spinner />
    </StyledContainer>
  );
}

Placeholder.displayName = "Placeholder";

export default Placeholder;
