import * as Sentry from "@sentry/browser";
import * as React from "react";

import AsyncComponent from "@/components/AsyncComponent";
import Placeholder from "@/components/Placeholder/Placeholder";
import { captureException } from "@/utils/error-logging";

type Props = {
  module: string;
  children: JSX.Element;
  serverError?: Error;
  debug?: boolean;
};

type State = {
  hasError: boolean;
  error: Error | null | undefined;
  errorInfo:
    | {
        [key: string]: any;
      }
    | null
    | undefined;
};
// This cannot be converted to functional components, as hooks don't support ComponentDidCatch
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
class ErrorBoundary extends React.Component<Props, State> {
  static displayName = "ErrorBoundary";
  static defaultProps = {
    module: "Unknown",
    debug: false,
  };

  state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidMount() {
    if (this.props.serverError && !this.state.hasError) {
      /* If the server threw an error, ensure it gets shown  */
      this.prepareServerError(this.props.serverError);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { error, errorInfo } = this.state;
    if (error && prevState.error !== error) {
      const child = React.Children.only(this.props.children);
      const props = child ? child.props : {};

      captureException(error, {
        props,
        module: this.props.module,
        info: errorInfo,
        location: global.location
          ? {
              hash: global.location.hash,
              href: global.location.href,
              pathname: global.location.pathname,
              search: global.location.search,
            }
          : null,
        serverSide: this.props.serverError && !errorInfo,
      });
    } else if (error) {
      // Try and recover from Error
      this.setState({
        hasError: false,
        error: null,
        errorInfo: null,
      });
    }
  }

  getFriendlyError = () => {
    if (process.env.SERVER) return null;
    else
      return import(
        /* webpackChunkName: "error" */
        "../components/Errors/FriendlyError/FriendlyError"
      );
  };

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    }
  ) {
    this.setState({ hasError: true, error, errorInfo });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      scope.setLevel(Sentry.Severity.Debug); //  possible values: error (default) | fatal | warning | info | debug
      Sentry.captureException(error);
    });
  }

  prepareServerError(serverError: Error) {
    if (serverError) {
      this.setState({
        // Only show server errors in debug mode
        hasError: this.props.debug || false,
        error: serverError,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.serverError && !this.props.debug) {
        // If the error already occurred on the server, and not in debug mode then don't render anything for this component
        return null;
      }
      const child = React.Children.only(this.props.children);
      const props = child ? child.props : {};
      const { error, errorInfo } = this.state;

      return (
        <AsyncComponent
          debug={this.props.debug}
          details={[
            {
              name: "Props",
              value: props,
            },
            { name: "Stacktrace", value: error ? error.stack : null },
            {
              name: "Info",
              value: errorInfo,
            },
          ]}
          handleReload={() => {
            this.setState({ hasError: false, error: undefined });
          }}
          loader={this.getFriendlyError}
          message={this.props.debug && error ? error.message : undefined}
          name={this.props.module || "Unknown"}
          renderPlaceholder={() => <Placeholder />}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
