import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ArrowNavIcon from "@/icons/ArrowNavIcon";
import { color } from "@/styles/theme";

import Button from "../Button/Button";
import Row from "../Grid/Row";
import { usePagination } from "./private/usePagination";

const PagingRow = styled(Row)`
  justify-content: center;
`;

const PagingButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 1px;
  font-size: 14;
`;

const Dots = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${color.bg};
  height: 50px;
  width: 30px;
  margin: 1px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  user-select: none;
`;

type Props = {
  goToPageCallback: (page: number) => void;
  currentPage: number;
  totalPages: number;
  siblings?: number;
};

const PagingControls = ({
  goToPageCallback,
  currentPage,
  totalPages,
  siblings = 0,
}: Props) => {
  //+1 to totalPages and current page so that the count starts at 1
  const paginationRange = usePagination(totalPages, siblings, currentPage + 1);
  const { t, ready } = useTranslation("aria");
  const nextAriaLabel = t("pagingNext");
  const previousAriaLabel = t("pagingPrevious");
  const pageLabel = t("page");

  if (totalPages <= 1 || isNaN(totalPages) || !ready) return null;

  return (
    <PagingRow data-testid="pagination-controls">
      <PagingButton
        aria-label={previousAriaLabel}
        disabled={currentPage === 0}
        onClick={() => goToPageCallback(currentPage - 1)}
      >
        <ArrowNavIcon flip={true} />
      </PagingButton>
      {paginationRange?.map((PageNumber, index) => {
        if (PageNumber === -1) {
          return <Dots key={index}>...</Dots>;
        }

        return (
          <PagingButton
            key={index}
            aria-label={`${pageLabel} ${PageNumber}`}
            onClick={() => goToPageCallback(PageNumber - 1)}
            variation={
              PageNumber === currentPage + 1 ? "background" : "primary"
            }
          >
            {PageNumber}
          </PagingButton>
        );
      })}
      <PagingButton
        aria-label={nextAriaLabel}
        disabled={currentPage + 1 >= totalPages}
        onClick={() => goToPageCallback(currentPage + 1)}
      >
        <ArrowNavIcon />
      </PagingButton>
    </PagingRow>
  );
};

export default PagingControls;
