// GENERATED FILE
// This file is automatically generated by "create-modules.ts" whenever you add or remove a module.
import type { ModuleNameEnum } from "@/view-models/enums/ModuleNameTypes";

/**
 * List all Modules that can be imported here.
 * Use `import()` to enable async loading of only the modules we need on a page.
 */
const dynamicModulesMap: { [key in ModuleNameEnum]: () => Promise<any> } = {
  Accordion: () =>
    import(/* webpackChunkName: 'accordion' */ "./Layout/Accordion/Accordion"),
  AnchorMenu: () =>
    import(
      /* webpackChunkName: 'anchor-menu' */ "./Layout/AnchorMenu/AnchorMenu"
    ),
  AssessmentExplanation: () =>
    import(
      /* webpackChunkName: 'assessment-explanation' */ "./PrivateAssessment/AssessmentExplanation/AssessmentExplanation"
    ),
  Breadcrumbs: () =>
    import(
      /* webpackChunkName: 'breadcrumbs' */ "./Layout/Breadcrumbs/Breadcrumbs"
    ),
  CludoSearchResults: () =>
    import(
      /* webpackChunkName: 'cludo-search-results' */ "./Layout/CludoSearchResults/CludoSearchResults"
    ),
  Compensation: () =>
    import(
      /* webpackChunkName: 'compensation' */ "./Layout/Compensation/Compensation"
    ),
  CompensationRouter: () =>
    import(
      /* webpackChunkName: 'compensation-router' */ "./Layout/CompensationRouter/CompensationRouter"
    ),
  Contact: () =>
    import(/* webpackChunkName: 'contact' */ "./Layout/Contact/Contact"),
  ContactAndObjectionCategories: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-categories' */ "./ContactAndObjection/shared/ContactAndObjectionCategories/ContactAndObjectionCategories"
    ),
  ContactAndObjectionContentSection: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-content-section' */ "./ContactAndObjection/shared/ContactAndObjectionContentSection/ContactAndObjectionContentSection"
    ),
  ContactAndObjectionFormIntro: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-form-intro' */ "./ContactAndObjection/shared/ContactAndObjectionFormIntro/ContactAndObjectionFormIntro"
    ),
  ContactAndObjectionHero: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-hero' */ "./ContactAndObjection/shared/ContactAndObjectionHero/ContactAndObjectionHero"
    ),
  ContactAndObjectionInfo: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-info' */ "./ContactAndObjection/shared/ContactAndObjectionInfo/ContactAndObjectionInfo"
    ),
  ContactAndObjectionIntro: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-intro' */ "./ContactAndObjection/shared/ContactAndObjectionIntro/ContactAndObjectionIntro"
    ),
  ContactAndObjectionReceipt: () =>
    import(
      /* webpackChunkName: 'contact-and-objection-receipt' */ "./ContactAndObjection/shared/ContactAndObjectionReceipt/ContactAndObjectionReceipt"
    ),
  ContactForm: () =>
    import(
      /* webpackChunkName: 'contact-form' */ "./ContactAndObjection/Contact/ContactForm/ContactForm"
    ),
  ContactStartLink: () =>
    import(
      /* webpackChunkName: 'contact-start-link' */ "./ContactAndObjection/Contact/ContactStartLink/ContactStartLink"
    ),
  DisplayList: () =>
    import(
      /* webpackChunkName: 'display-list' */ "./Layout/DisplayList/DisplayList"
    ),
  DisplayListGroup: () =>
    import(
      /* webpackChunkName: 'display-list-group' */ "./Layout/DisplayListGroup/DisplayListGroup"
    ),
  DisplayListGroupSection: () =>
    import(
      /* webpackChunkName: 'display-list-group-section' */ "./Layout/DisplayListGroupSection/DisplayListGroupSection"
    ),
  ErrorPage: () =>
    import(/* webpackChunkName: 'error-page' */ "./Layout/ErrorPage/ErrorPage"),
  FactsBox: () =>
    import(/* webpackChunkName: 'facts-box' */ "./Layout/FactsBox/FactsBox"),
  Footer: () =>
    import(/* webpackChunkName: 'footer' */ "./Layout/Footer/Footer"),
  GroundValue: () =>
    import(
      /* webpackChunkName: 'ground-value' */ "./PrivateAssessment/GroundValue/GroundValue"
    ),
  GuidesOverview: () =>
    import(
      /* webpackChunkName: 'guides-overview' */ "./Layout/GuidesOverview/GuidesOverview"
    ),
  Header: () =>
    import(/* webpackChunkName: 'header' */ "./Layout/Header/Header"),
  HeaderBanner: () =>
    import(
      /* webpackChunkName: 'header-banner' */ "./Layout/Header/HeaderBanner/HeaderBanner"
    ),
  HelpPanel: () =>
    import(/* webpackChunkName: 'help-panel' */ "./Layout/HelpPanel/HelpPanel"),
  Hero: () => import(/* webpackChunkName: 'hero' */ "./Layout/Hero/Hero"),
  HistoricAssessmentComplaintDeadline: () =>
    import(
      /* webpackChunkName: 'historic-assessment-complaint-deadline' */ "./Layout/HistoricAssessmentComplaintDeadline/HistoricAssessmentComplaintDeadline"
    ),
  Introduction: () =>
    import(
      /* webpackChunkName: 'introduction' */ "./Layout/Introduction/Introduction"
    ),
  LoginRouter: () =>
    import(
      /* webpackChunkName: 'login-router' */ "./Layout/LoginRouter/LoginRouter"
    ),
  Media: () => import(/* webpackChunkName: 'media' */ "./Layout/Media/Media"),
  MunicipalityLookup: () =>
    import(
      /* webpackChunkName: 'municipality-lookup' */ "./PreliminaryAssessment/MunicipalityLookup/MunicipalityLookup"
    ),
  MyProperties: () =>
    import(
      /* webpackChunkName: 'my-properties' */ "./Layout/MyProperties/MyProperties"
    ),
  MyPropertyAssessmentComplaint: () =>
    import(
      /* webpackChunkName: 'my-property-assessment-complaint' */ "./PrivateAssessment/MyPropertyAssessmentComplaint/MyPropertyAssessmentComplaint"
    ),
  MyPropertyBuildingSection: () =>
    import(
      /* webpackChunkName: 'my-property-building-section' */ "./Declaration/MyPropertyBuildingSection/MyPropertyBuildingSection"
    ),
  MyPropertyDocuments: () =>
    import(
      /* webpackChunkName: 'my-property-documents' */ "./Declaration/MyPropertyDocuments/MyPropertyDocuments"
    ),
  MyPropertyGroundSection: () =>
    import(
      /* webpackChunkName: 'my-property-ground-section' */ "./Declaration/MyPropertyGroundSection/MyPropertyGroundSection"
    ),
  MyPropertyGroundValueCondoSection: () =>
    import(
      /* webpackChunkName: 'my-property-ground-value-condo-section' */ "./Declaration/MyPropertyGroundValueCondoSection/MyPropertyGroundValueCondoSection"
    ),
  MyPropertyHero: () =>
    import(
      /* webpackChunkName: 'my-property-hero' */ "./Layout/MyPropertyHero/MyPropertyHero"
    ),
  MyPropertyIntroAssessment: () =>
    import(
      /* webpackChunkName: 'my-property-intro-assessment' */ "./PrivateAssessment/MyPropertyIntroAssessment/MyPropertyIntroAssessment"
    ),
  MyPropertyIntroDeclaration: () =>
    import(
      /* webpackChunkName: 'my-property-intro-declaration' */ "./Declaration/MyPropertyIntroDeclaration/MyPropertyIntroDeclaration"
    ),
  MyPropertyObjectionSection: () =>
    import(
      /* webpackChunkName: 'my-property-objection-section' */ "./Declaration/MyPropertyObjectionSection/MyPropertyObjectionSection"
    ),
  MyPropertySurroundingsSection: () =>
    import(
      /* webpackChunkName: 'my-property-surroundings-section' */ "./Declaration/MyPropertySurroundingsSection/MyPropertySurroundingsSection"
    ),
  ObjectionForm: () =>
    import(
      /* webpackChunkName: 'objection-form' */ "./ContactAndObjection/Objection/ObjectionForm/ObjectionForm"
    ),
  ObjectionSend: () =>
    import(
      /* webpackChunkName: 'objection-send' */ "./ContactAndObjection/Objection/ObjectionSend/ObjectionSend"
    ),
  ObjectionSummary: () =>
    import(
      /* webpackChunkName: 'objection-summary' */ "./ContactAndObjection/Objection/ObjectionSummary/ObjectionSummary"
    ),
  PreliminaryLookup: () =>
    import(
      /* webpackChunkName: 'preliminary-lookup' */ "./PreliminaryAssessment/PreliminaryLookup/PreliminaryLookup"
    ),
  PreliminaryPropertiesOverview: () =>
    import(
      /* webpackChunkName: 'preliminary-properties-overview' */ "./PreliminaryAssessment/PreliminaryPropertiesOverview/PreliminaryPropertiesOverview"
    ),
  PreliminaryPropertyIntroAssessment: () =>
    import(
      /* webpackChunkName: 'preliminary-property-intro-assessment' */ "./PreliminaryAssessment/PreliminaryPropertyIntroAssessment/PreliminaryPropertyIntroAssessment"
    ),
  ProfileBanner: () =>
    import(
      /* webpackChunkName: 'profile-banner' */ "./Layout/Header/ProfileBanner/ProfileBanner"
    ),
  PromotedContent: () =>
    import(
      /* webpackChunkName: 'promoted-content' */ "./Layout/PromotedContent/PromotedContent"
    ),
  PromotedContentList: () =>
    import(
      /* webpackChunkName: 'promoted-content-list' */ "./Layout/PromotedContentList/PromotedContentList"
    ),
  PropertySearch: () =>
    import(
      /* webpackChunkName: 'property-search' */ "./Layout/PropertySearch/PropertySearch"
    ),
  PropertyTypeModal: () =>
    import(
      /* webpackChunkName: 'property-type-modal' */ "./Layout/PropertyTypeModal/PropertyTypeModal"
    ),
  PropertyValue: () =>
    import(
      /* webpackChunkName: 'property-value' */ "./PrivateAssessment/PropertyValue/PropertyValue"
    ),
  PublicPropertyHero: () =>
    import(
      /* webpackChunkName: 'public-property-hero' */ "./PublicAssessment/PublicPropertyHero/PublicPropertyHero"
    ),
  PublicPropertyIntroAssessment: () =>
    import(
      /* webpackChunkName: 'public-property-intro-assessment' */ "./PublicAssessment/PublicPropertyIntroAssessment/PublicPropertyIntroAssessment"
    ),
  Quote: () => import(/* webpackChunkName: 'quote' */ "./Layout/Quote/Quote"),
  ReferenceProperty: () =>
    import(
      /* webpackChunkName: 'reference-property' */ "./Declaration/ReferenceProperty/ReferenceProperty"
    ),
  ReferencePropertyLookup: () =>
    import(
      /* webpackChunkName: 'reference-property-lookup' */ "./Declaration/ReferencePropertyLookup/ReferencePropertyLookup"
    ),
  RelatedPages: () =>
    import(
      /* webpackChunkName: 'related-pages' */ "./Layout/RelatedPages/RelatedPages"
    ),
  ResetCookies: () =>
    import(
      /* webpackChunkName: 'reset-cookies' */ "./Layout/ResetCookies/ResetCookies"
    ),
  RichTextModule: () =>
    import(
      /* webpackChunkName: 'rich-text-module' */ "./Layout/RichTextModule/RichTextModule"
    ),
  SearchResults: () =>
    import(
      /* webpackChunkName: 'search-results' */ "./Layout/SearchResults/SearchResults"
    ),
  StatusBar: () =>
    import(/* webpackChunkName: 'status-bar' */ "./Layout/StatusBar/StatusBar"),
  StepByStepGuide: () =>
    import(
      /* webpackChunkName: 'step-by-step-guide' */ "./Layout/StepByStepGuide/StepByStepGuide"
    ),
  SubjectOverview: () =>
    import(
      /* webpackChunkName: 'subject-overview' */ "./Layout/SubjectOverview/SubjectOverview"
    ),
  SurveyModal: () =>
    import(
      /* webpackChunkName: 'survey-modal' */ "./Layout/SurveyModal/SurveyModal"
    ),
  TaxCalculator: () =>
    import(
      /* webpackChunkName: 'tax-calculator' */ "./PreliminaryAssessment/TaxCalculator/TaxCalculator"
    ),
  Timeline: () =>
    import(/* webpackChunkName: 'timeline' */ "./Layout/Timeline/Timeline"),
  TimelineGroup: () =>
    import(
      /* webpackChunkName: 'timeline-group' */ "./Layout/TimelineGroup/TimelineGroup"
    ),
  VideoList: () =>
    import(/* webpackChunkName: 'video-list' */ "./Layout/VideoList/VideoList"),
};

export default function getModule(name: ModuleNameEnum) {
  return dynamicModulesMap[name];
}
