import { rem } from "polished";
import * as React from "react";
import styled from "styled-components";

import { mediaOnly } from "@/styles/media";

type Props = {
  children?: React.ReactNode;
  cite?: string;
  size: "normal" | "small";
};

const StyledWrapper = styled.blockquote`
  padding: 0;
  margin: 80px 0;

  ${mediaOnly.xs`
    margin: 40px 0;
  `};
`;

const StyledQuoteParagraph = styled.p<{
  size: string;
}>`
  margin: 0;
  font-weight: 700;
  font-size: ${(p) => (p.size === "small" ? rem(25) : "2rem")};
  line-height: ${(p) => (p.size === "small" ? "1.44" : "1.31")};

  ${mediaOnly.xs`
    font-size: ${(p) => (p.size === "small" ? rem(18) : rem(22))};
    line-height: ${(p) => (p.size === "small" ? "1.5" : "1.36")};
  `};
`;

const StyledCite = styled.cite`
  margin-top: 1rem;
  display: block;
  font-style: normal;
  text-align: right;
  font-size: 1rem;
`;

function Blockquote({ children, cite, size }: Props) {
  return (
    <StyledWrapper>
      <StyledQuoteParagraph size={size}>{children}</StyledQuoteParagraph>
      {cite ? <StyledCite>- {cite}</StyledCite> : null}
    </StyledWrapper>
  );
}

Blockquote.displayName = "Blockquote";
Blockquote.defaultProps = {
  size: "normal",
};

export default Blockquote;
