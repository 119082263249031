import * as React from "react";

export const usePagination = (
  totalPageCount: number,
  siblingCount = 1,
  currentPage: number
) => {
  const paginationRange = React.useMemo(() => {
    const range = (start: number, end: number) => {
      const length = end - start + 1;

      return Array.from({ length }, (_, idx) => idx + start);
    };

    //+5 to account for the first page, last page, current page, and the 2 dot elements
    const totalPageNumbers = siblingCount + 5;

    //The number of pages is less than the page numbers we want to show
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const showLeftDots = leftSiblingIndex > 3;
    const showRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    const dots = -1;

    if (!showLeftDots && showRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, dots, totalPageCount];
    }

    if (showLeftDots && !showRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );

      return [firstPageIndex, dots, ...rightRange];
    }

    const middleRange = range(leftSiblingIndex, rightSiblingIndex);

    return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex];
  }, [totalPageCount, siblingCount, currentPage]);

  return paginationRange;
};
