import * as React from "react";

type Props = {
  width?: string;
  height?: string;
};

export default (props: Props) => (
  <svg aria-hidden="true" height="16" viewBox="0 0 16 16" width="16" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
      transform="translate(1 1)"
    >
      <circle cx="5.5" cy="5.5" r="5.5" />
      <path d="M9.5 9.5l3.536 3.536" strokeLinecap="square" />
    </g>
  </svg>
);
