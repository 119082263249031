import useMedia from "@charlietango/use-media";
import * as React from "react";
import { useEffect } from "react";

import { breakpoints } from "@/styles/media";
import { LOCK_STATUS } from "@/utils/actions";
import { subscribe, unsubscribe } from "@/utils/dispatcher";

type Props = {
  children?: React.ReactNode;
};

/*
 * LookController is used for adding "fixed" css class.
 * Used in HelpPanel when open/closing helpPanel view on Mobile
 * */
function LockController({ children }: Props) {
  const [lockState, setLockState] = React.useState(false);
  const matches = useMedia({ minWidth: `${breakpoints.sm}em` });

  useEffect(() => {
    subscribe(LOCK_STATUS, (payload) => setLockState(payload));

    return () => {
      unsubscribe(LOCK_STATUS, () => {});
    };
  }, []);

  return (
    <div className={lockState && !matches ? "fixed" : undefined}>
      {children}
    </div>
  );
}

LockController.displayName = "LockController";
LockController.defaultProps = {};

export default LockController;
