import * as React from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

type Props = {
  children?: React.ReactNode;
  className?: string;
  instant?: boolean;
  color?: string;
  loadingLabel?: string;
};

type StyledProps = {
  top?: string;
  left?: string;
  delay: number;
};

const fade = keyframes`
  0% {
    opacity: 0;
  }
 
  100% {
    opacity: 1;
  }
`;

const pop = keyframes`
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const StyledWrapper = styled.span`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledInner = styled.span`
  display: block;
  position: relative;
  width: 54px;
  height: 18px;
`;

const StyledFadedInner = styled(StyledInner)`
  opacity: 0;
  animation: ${fade} 0.3s ease 0.6s forwards;
`;

const StyledSpan = styled.span<StyledProps>`
  position: absolute;
  transform-origin: center;
  display: inline-block;
  width: 18px;
  height: 6px;
  transform: scale(0);
  animation: ${pop} 0.5s infinite ease-in-out ${(p) => p.delay * 0.2 || 0.8}s
    alternate;
  top: ${(p) => p.top || "0"};
  left: ${(p) => p.left || "0"};
  background-color: ${(p) => p.color || "currentColor"};
  will-change: transform;
`;
const Spinner = ({ className, instant, loadingLabel }: Props) => {
  const { t, ready } = useTranslation("common");
  const prefix = ready ? t("loadingAriaLabel") : "";
  const Container = instant ? StyledInner : StyledFadedInner;

  return (
    <StyledWrapper
      aria-label={`${prefix} ${loadingLabel ?? ""}`}
      className={className}
      role={"alert"}
    >
      <Container data-testid="spinner">
        <StyledSpan delay={0.4} top={"6px"} />
        <StyledSpan delay={1.2} left={"18px"} top={"12px"} />
        <StyledSpan delay={2} left={"36px"} />
      </Container>
    </StyledWrapper>
  );
};

Spinner.displayName = "Spinner";

export default Spinner;
