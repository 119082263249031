import * as React from "react";
import styled from "styled-components";

import BaseButton from "@/components/Button/BaseButton";
import HelpIcon from "@/icons/HelpIcon";
import { getFontSize, getLineHeight } from "@/styles/style-helpers";
import { color } from "@/styles/theme";
import { HELPPANEL_SHOW_ITEM } from "@/utils/actions";
import { dispatch } from "@/utils/dispatcher";
import generateTrackingAttributes from "@/utils/generateTrackingAttributes";
import { HelpButtonViewModel } from "@/view-models/HelpButtonViewModel";

type StyledProps = {
  marginLeft?: boolean;
  size?: "tiny" | "small" | "normal";
  variant?: "inverse" | "default";
  weight?: "400" | "500";
};

type Props = {
  help: HelpButtonViewModel;
  ariaDescribedBy?: string;
} & StyledProps;

const StyledButtonWrapper = styled.span<StyledProps>`
  display: inline-flex;
  margin-left: ${(p) => (p.marginLeft ? "10px" : 0)};
  align-items: baseline;
  justify-content: center;
  height: 100%;
  color: ${(p) => (p.variant === "default" ? color.grey : color.blue)};

  &:hover,
  &:active {
    color: ${(p) => (p.variant === "default" ? color.altBlue : color.grey)};
  }
`;

const StyledButtonIcon = styled(BaseButton)<React.ButtonHTMLAttributes<any>>`
  svg {
    transform: translateY(-1px);
  }
`;

const StyledInnerWrapper = styled.span`
  display: flex;
  align-items: flex-start;
`;

const StyledLabel = styled.span<{
  rightPadded?: boolean;
  size: "tiny" | "small" | "normal";
}>`
  font-size: ${(p) => getFontSize(p.size)};
  line-height: ${(p) => getLineHeight(p.size)};
  padding: ${(p) => (p.rightPadded ? "1px 5px 0 0" : "1px 0 0 5px")};
`;

const clickHandler = (help: HelpButtonViewModel) => {
  dispatch(HELPPANEL_SHOW_ITEM, help);
};
function HelpIconButton({
  help,
  marginLeft = true,
  ariaDescribedBy,
  size = "small",
  variant = "default",
  weight,
}: Props) {
  return (
    <StyledButtonWrapper
      marginLeft={marginLeft}
      variant={variant}
      weight={weight}
    >
      <StyledButtonIcon
        aria-label={help.title ? help.title : "help-button"}
        data-testid={"helpButtonIcon"}
        onClick={() => clickHandler(help)}
        title={help.title ? help.title : "help-button"}
        {...generateTrackingAttributes({
          section: "helpIconButton",
          type: help.title ? help.title : "help-button",
          eventName: "helpIconButtonClicked",
          name: help.label ? help.label : help.title,
        })}
      >
        {help.label ? (
          <StyledInnerWrapper>
            {help?.iconPlacement === "right" ? (
              <>
                <StyledLabel rightPadded size={size}>
                  {help.label}
                </StyledLabel>
                <HelpIcon />
              </>
            ) : (
              <>
                <HelpIcon />
                <StyledLabel size={size}>{help.label}</StyledLabel>
              </>
            )}
          </StyledInnerWrapper>
        ) : (
          <HelpIcon />
        )}
      </StyledButtonIcon>
    </StyledButtonWrapper>
  );
}

HelpIconButton.displayName = "HelpIconButton";

export default HelpIconButton;
