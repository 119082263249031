import useClientHydrated from "@charlietango/use-client-hydrated";
import * as React from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  /** If you supply an id, then it will be set as the value of `data-portal`, allowing you to identify the portal container in the DOM. */
  id?: string;
  children: React.ReactNode;
};

/**
 * Render a new portal at the root of the document (inside <body>)
 * This is based on the implementation in @charlietango/ui, and ensures proper Portal management
 * */
const Portal = ({ children, id }: PortalProps) => {
  const clientHydrated = useClientHydrated();
  const portalNode = React.useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = React.useState(clientHydrated);

  if (!portalNode.current && mounted) {
    // If the client is hydrated, we can attach the <div> element now. Otherwise, we wait until the useEffect triggers
    portalNode.current = document.createElement("div");
    if (document.body) document.body.appendChild(portalNode.current);
  }

  React.useEffect(() => {
    if (!portalNode.current) {
      // After hydrating, we can safely create the containing div element
      portalNode.current = document.createElement("div");
      // Update mounted state, so the portal is rendered into the new div
      setMounted(true);
    }
    const node = portalNode.current;

    if (node && document.body) {
      document.body.appendChild(node);

      return () => {
        if (document.body) document.body.removeChild(node);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (portalNode.current) {
      // Set the data-portal attribute
      portalNode.current.setAttribute("data-portal", id || "");
    }
  }, [id]);

  return portalNode.current ? createPortal(children, portalNode.current) : null;
};

Portal.displayName = "Portal";

export default Portal;
