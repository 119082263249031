import * as React from "react";
import styled from "styled-components";

import Link, { isExternalLink } from "@/components/Link/Link";
import { color } from "@/styles/theme";
import { TrackingTypes } from "@/types/TrackingTypes";
import generateTrackingAttributes from "@/utils/generateTrackingAttributes";

type Props = {
  children?: React.ReactNode;
  nodeKey?: string;
  literal?: string;
  href: string;
  title?: string;
  isAccordion?: boolean;
};

const StyledUnderlineLink = styled(Link)`
  && {
    text-decoration: underline;

    &:hover {
      color: ${color.darkGrey};
    }
  }
`;

const LinkRenderer = ({ children, href, title, isAccordion }: Props) => {
  const isExternal = isExternalLink(href);

  const tracking: TrackingTypes = {
    section: isAccordion ? "accordion" : "article",
    name:
      typeof children === "string"
        ? children
        : Array.isArray(children) && typeof children[0] === "string"
        ? children[0]
        : title
        ? title
        : href,
    eventName: isAccordion ? "accordionLinkClick" : "articleLinkClick",
  };

  return (
    <StyledUnderlineLink
      href={href}
      label={title}
      target={isExternal ? "_blank" : "_self"}
      {...generateTrackingAttributes(tracking)}
    >
      {children}
    </StyledUnderlineLink>
  );
};

LinkRenderer.displayName = "LinkRenderer";

export default LinkRenderer;
