import { gql, useQuery } from "@apollo/client";
import { useAtom } from "jotai";
import { isEqual } from "lodash";
import React from "react";

import { activeYearAtom, valuationYearAtom } from "@/store";
import { usePrevious } from "@/utils/hooks/usePrevious";

import useQueryParams from "./useQueryParams";

const VALUATION_YEAR = gql`
  query GetValuationYear($vurderingsejendom_id: Int! = -1) {
    vurderingsejendom(vurderingsejendom_id: $vurderingsejendom_id) {
      vurderingsejendom_id
      vurderingssager(vurderingsaar: []) {
        vurderingsaar
        vurderingsejendom_id
      }
    }
  }
`;

export function useValuationYear() {
  const [activeYear, setActiveYear] = useAtom(activeYearAtom);
  const [, setValuationYear] = useAtom(valuationYearAtom);
  const queryParams = useQueryParams();

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(VALUATION_YEAR, {
    variables: {
      vurderingsejendom_id: queryParams.ejendomsid,
    },
    skip: !queryParams?.ejendomsid,
  });

  React.useEffect(() => {
    if (data?.vurderingsaar) {
      setValuationYear(data.vurderingsaar);
    }
  }, [data, setValuationYear]);

  const mappedYears = React.useMemo(
    () =>
      data?.vurderingsejendom?.vurderingssager
        .map(
          (sag) => sag.vurderingsaar // get valuation years
        )
        .sort((a, b) => a - b), // sort low to high until year handling in 24.5 DONT MERGE THIS INTO 24.5
    [data]
  );

  const loading = !queryLoading && !!mappedYears;
  const previousPropertyId = usePrevious(queryParams.ejendomsid);
  const previousMappedYears = usePrevious(mappedYears);
  const previousActiveYear = usePrevious(activeYear);

  const hasChanged =
    previousPropertyId !== queryParams?.ejendomsid ||
    !isEqual(previousMappedYears, mappedYears) ||
    !isEqual(previousActiveYear, activeYear);

  React.useEffect(() => {
    if (
      queryParams?.ejendomsid &&
      mappedYears &&
      hasChanged &&
      activeYear[queryParams.ejendomsid]?.setBy !== "User"
    ) {
      setActiveYear({
        [queryParams.ejendomsid as number]: {
          setBy: "System",
          year: mappedYears[0],
        },
      });
    }
  }, [queryParams, activeYear, mappedYears, setActiveYear, hasChanged]);

  return [{ loading, error }];
}
