import { em } from "polished";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { mediaOnly } from "@/styles/media";

import { AreaProvider } from "../AreaContext/AreaContext";
import { ArticleProvider } from "../ArticleContext/ArticleContext";

type Props = {
  children?: React.ReactNode;
};

const StyledArticleEl = styled.article`
  max-width: ${em(920)};
  margin: 0 auto 80px;

  ${mediaOnly.xs`
    margin-bottom: 40px;
  `};
`;

const Article = ({ children, ...rest }: Props) => {
  const [width, setWidth] = useState(0);
  const article = useRef<HTMLElement>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleResize = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (article && article.current && article.current.clientWidth !== width)
          setWidth(article.current.clientWidth);
      }, 50);
    };
    window.addEventListener("resize", handleResize);

    if (article.current) handleResize();

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [article, width]);

  return (
    <AreaProvider value="article">
      <ArticleProvider value={width}>
        <StyledArticleEl ref={article} {...rest}>
          {children}
        </StyledArticleEl>
      </ArticleProvider>
    </AreaProvider>
  );
};

export default Article;
