import useMedia from "@charlietango/use-media";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { LinkViewModel } from "@/view-models/LinkViewModel";

import { breakpoints, mediaQuery } from "../../styles/media";
import { color } from "../../styles/theme";
import { ButtonVariation } from "../Button/Button";
import Button from "../Button/Button";
import Heading from "../Heading/Heading";
import Richtext from "../Richtext/Richtext";
import Modal from "./Modal";

export type ActionType = LinkViewModel & {
  onClick?: () => void;
  variation: ButtonVariation;
  submitting?: boolean;
};

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  onClosed?: () => void;
  headline?: string;
  body?: string | React.ReactNode;
  primaryAction?: ActionType;
  actions?: Array<ActionType>;
  disableWrapperMaxHeight?: boolean;
};

const StyledWrapper = styled.div<{
  hasActions?: boolean;
  disableWrapperMaxHeight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.hasActions ? "290px" : "380px")};
  max-height: ${(props) =>
    !props.disableWrapperMaxHeight
      ? props.hasActions
        ? "calc(100% - 203px)"
        : "unset"
      : "unset"};
  background-color: ${color.white};
  overflow: auto;

  ${mediaQuery.sm`
    width: 660px;
    max-height: ${(props) =>
      !props.disableWrapperMaxHeight
        ? props.hasActions
          ? "370px"
          : "460px"
        : "unset"};
  `};
`;

const StyledContent = styled.div`
  padding: 0 20px 20px 20px;

  ${mediaQuery.sm`
    position: relative;
    padding: 46px 40px 40px 40px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 40px;
      display: inline-block;
      width: 60px;
      height: 16px;
      background-color: ${color.altBlue};
    }
  `};
`;

const StyledNavigation = styled.div`
  width: 100%;
  border-top: 1px solid ${color.light};
  padding: 20px;
  display: flex;
  min-height: 90px;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  background-color: ${color.white};
  flex-direction: column;

  ${mediaQuery.sm`
    flex-direction: row-reverse;
  `};
`;

const StyledActionButton = styled(Button)<{
  variation?: string;
  longText?: boolean;
}>`
  color: ${(props) =>
    props.variation === "text" ? `${color.grey}` : undefined};

  ${mediaQuery.sm`
    flex: 0 1 ${(props) =>
      props.longText ? "185px" : props.variation === "text" ? "auto" : "140px"};
    margin-right: 10px;

    &:first-child {
      flex: 0 1 ${(props) =>
        props.longText
          ? "185px"
          : props.variation === "text"
          ? "auto"
          : "150px"};
      margin-right: 0;
    }
  `};
`;
const ActionModal = ({
  isOpen,
  onRequestClose,
  onClosed,
  headline,
  body,
  children,
  primaryAction,
  actions,
  disableWrapperMaxHeight = false,
}: Props) => {
  const isDesktop = useMedia(`(min-width: ${breakpoints.sm}em)`);
  const { t, ready } = useTranslation("common");

  if (!ready) return null;

  return (
    <Modal
      ariaLabel={headline ? headline : t("modalAriaLabel")}
      isOpen={isOpen}
      onClosed={onClosed}
      onRequestClose={onRequestClose}
    >
      <StyledWrapper
        disableWrapperMaxHeight={disableWrapperMaxHeight}
        hasActions={!!actions}
      >
        <StyledContent>
          {headline ? (
            <Heading level={4} tag="h2">
              {headline}
            </Heading>
          ) : null}

          {body && typeof body === "string" ? (
            <Richtext size="small" source={body} />
          ) : (
            body
          )}

          {children}
        </StyledContent>
      </StyledWrapper>

      {actions || primaryAction ? (
        <StyledNavigation>
          {primaryAction && (
            <StyledActionButton {...primaryAction} fillWidth={!isDesktop} />
          )}
          {actions &&
            actions.map((action, key) => {
              const { ...rest } = action;
              const longText =
                !!action?.label?.length && action?.label?.length > 15;

              return (
                <StyledActionButton
                  key={key}
                  href=""
                  {...rest}
                  fillWidth={!isDesktop}
                  longText={longText}
                />
              );
            })}
        </StyledNavigation>
      ) : null}
    </Modal>
  );
};

ActionModal.displayName = "ActionModal";

export default ActionModal;
