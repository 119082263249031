import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { color } from "@/styles/theme";

const StyledTermin = styled.span`
  font-size: ${rem(14)};
  color: ${color.blue};
  margin-left: 10px;
  font-weight: 500;
`;

const Vurderingstermin = () => {
  return <StyledTermin>vurderingstermin</StyledTermin>;
};

Vurderingstermin.displayName = "Vurderingstermin";

export default Vurderingstermin;
