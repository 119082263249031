import {
  DATA_TRACKING_EVENT_NAME,
  DATA_TRACKING_FILE_NAME,
  DATA_TRACKING_FILE_TYPE,
  DATA_TRACKING_FILE_URL,
  DATA_TRACKING_LINK_TITLE,
  DATA_TRACKING_NAME,
  DATA_TRACKING_SECTION,
  DATA_TRACKING_SUB_TITLE,
  DATA_TRACKING_TITLE,
  DATA_TRACKING_TYPE,
} from "../App/tracking/tracking";
import { TrackingTypes } from "../types/TrackingTypes";

function generateTrackingAttributes(tracking: TrackingTypes | undefined) {
  if (!tracking) return undefined;

  return {
    [DATA_TRACKING_SECTION]: tracking.section,
    [DATA_TRACKING_TYPE]: tracking.type,
    [DATA_TRACKING_NAME]: tracking.name,
    [DATA_TRACKING_EVENT_NAME]: tracking.eventName,
    [DATA_TRACKING_TITLE]: tracking.title,
    [DATA_TRACKING_SUB_TITLE]: tracking.subTitle,
    [DATA_TRACKING_LINK_TITLE]: tracking.linkTitle,
    [DATA_TRACKING_FILE_NAME]: tracking.fileName,
    [DATA_TRACKING_FILE_TYPE]: tracking.fileType,
    [DATA_TRACKING_FILE_URL]: tracking.fileLinkURL,
  };
}

export default generateTrackingAttributes;
