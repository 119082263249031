import { useMemo } from "react";

import { useRouter } from "../../App/Router";
import { objectFromURLSearchParams } from "../url-helpers";

function useQueryParams(): {
  [key: string]: number | string;
} {
  const { location } = useRouter();

  return useMemo(
    () => objectFromURLSearchParams(new URLSearchParams(location.search)),
    [location.search]
  );
}

export default useQueryParams;
