// @ts-ignore
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";

import Link from "@/components/Link/Link";
import { objectFitSupport } from "@/utils/css-supports";
import invariant from "@/utils/invariant";
import { ImageViewModel } from "@/view-models/ImageViewModel";

import { Fill } from "../../styles/blocks";
import Spinner from "../Spinner/Spinner";
import {
  calculateRatio,
  generateSizes,
  generateSrc,
  generateSrcSet,
  IMAGE_WIDTHS,
  ImageRatio,
  ImageSizes,
  sizeToNumber,
} from "./image-sources";

type FitProps = {
  /** Horizontal align the image when using object fit */
  alignX?: "left" | "center" | "right";

  /** Vertical align the image when using object fit */
  alignY?: "top" | "center" | "bottom";

  /** Fit defaults to 'cover' if the ratio is set. Otherwise it's 'none' */
  fit?: "contain" | "cover" | "none";
  fill?: boolean;
};

type Props = ImageViewModel & {
  children?: React.ReactNode;
  sizes: ImageSizes;
  ratio?: ImageRatio;
  onLoad?: (...args: Array<any>) => any;
  className?: string;
  lazy?: boolean;
  hideSpinner?: boolean;
} & FitProps;

function getObjectPosition(
  alignX?: string,
  alignY?: string
): string | undefined {
  return alignX || alignY
    ? `${alignX || "center"} ${alignY || "center"}`
    : undefined;
}

const StyledAspectWrapper = styled.span<{
  fillWrapper?: boolean;
  aspect: number;
}>`
  display: block;
  ${({ fillWrapper, aspect }) =>
    !fillWrapper
      ? css`
          position: relative;
          padding-bottom: ${aspect * 100}%;
          width: 100%;
          overflow: hidden;
        `
      : css`
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          right: 0;
        `}
`;

const StyledBasicImg = styled.img.attrs<{ imageWidth: string }>(
  ({ imageWidth }) => ({
    style: {
      width: imageWidth ?? "100%",
    },
  })
)`
  height: auto;
`;

const ImageElement = Fill.withComponent("img");

const StyledObjectFitImg = styled(ImageElement)<
  FitProps & { isLoaded: boolean }
>`
  object-position: ${(p: FitProps) => getObjectPosition(p.alignX, p.alignY)};
  object-fit: ${(p) => p.fit};
  display: none;

  @supports (object-fit: contain) {
    display: ${(p) => (p.isLoaded ? "block" : "none")};
  }
`;

const StyledSatellite = styled.span`
  position: absolute;
  pointer-events: none;
  visibility: hidden;
  height: 100%;
  width: 100%;
`;

const getReducer =
  (imgWidth = 0, maxWidth: number): ((...args: Array<any>) => any) =>
  (prev: number, curr: number) =>
    Math.abs(curr - Math.min(imgWidth, maxWidth)) <
    Math.abs(prev - Math.min(imgWidth, maxWidth))
      ? curr
      : prev;

const ImageLink = ({ children, ...rest }) => <Link {...rest}>{children}</Link>;

const Image = ({
  children,
  className,
  src,
  height,
  width,
  alt,
  sizes,
  ratio = "original",
  alignX,
  alignY,
  fit,
  fill,
  onLoad,
  hideSpinner = false,
  lazy,
  navigationLink,
}: Props) => {
  const supportsLazyLoading = useNativeLazyLoading();
  const [supportsObjectFit, setSupportsObjectFit] = React.useState(true);
  const [currentSrc, setCurrentSrc] = React.useState<string | null>(null);
  const { ref: sateliteRef, inView } = useInView({
    triggerOnce: true,
    rootMargin: "400px 200px",
    skip: supportsLazyLoading,
  });
  const [load, setLoad] = React.useState(
    lazy ? supportsLazyLoading || inView : false
  );

  const imageRef: {
    current: null | HTMLImageElement | any;
  } = React.createRef();

  React.useEffect(() => {
    if (global.picturefill) {
      global.picturefill(imageRef.current);
    }
  }, [load, imageRef]);

  React.useEffect(() => {
    setSupportsObjectFit(objectFitSupport());
  }, []);

  React.useEffect(() => {
    if (!supportsObjectFit && !currentSrc && imageRef.current?.src) {
      setCurrentSrc(imageRef.current.src);
    }
    if (load === false) {
      setLoad(lazy ? inView : true);
    }
  }, [supportsObjectFit, imageRef, inView, lazy, load, currentSrc]);

  const handleError = () => {};

  const objectFit =
    fit ||
    (fill ? "cover" : undefined) ||
    (ratio && ratio !== "original" ? "cover" : undefined);

  const isBasic = (!width || !height) && !objectFit;
  const isLink = !!navigationLink;
  const isSvg =
    src && (src.endsWith(".svg") || src.startsWith("data:image/svg+xml;"));
  const conditionalSpinner =
    supportsLazyLoading && lazy ? null : !hideSpinner && !load ? (
      <Spinner />
    ) : null;

  const ImageComp = !isBasic
    ? objectFit
      ? StyledObjectFitImg
      : ImageElement
    : StyledBasicImg;

  if (process.env.NODE_ENV !== "production" && !isSvg) {
    invariant(
      sizes,
      'Images needs "sizes" to define correct responsive image sizes'
    );
  }

  const sizeReducer = getReducer(width, sizeToNumber(sizes.maxWidth));
  const closestImage = IMAGE_WIDTHS.reduce(sizeReducer);

  const imgSrc =
    width && sizes.maxWidth && !isSvg
      ? generateSrc(src, closestImage, ratio)
      : src;

  const img = React.useMemo(() => {
    const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
      if (onLoad) {
        onLoad(e);
      }
      setCurrentSrc(e.currentTarget.currentSrc);
    };

    return (
      <ImageComp
        ref={imageRef}
        // @ts-ignore
        alignX={alignX}
        alignY={alignY}
        alt={alt || ""}
        data-testid={"image"}
        fit={objectFit}
        imageWidth={sizes.maxWidth}
        isLoaded={load || supportsLazyLoading}
        loading={supportsLazyLoading && lazy ? "lazy" : undefined}
        onError={handleError}
        onLoad={!supportsObjectFit ? handleLoad : onLoad}
        role={!alt ? "presentation" : undefined}
        sizes={
          load && !isSvg
            ? generateSizes(sizes.breakpoints, sizes.maxWidth)
            : undefined
        }
        src={imgSrc}
        srcSet={
          (supportsLazyLoading || load || isBasic) && !isSvg
            ? generateSrcSet(src, ratio, sizes.maxWidth, width)
            : undefined
        }
      />
    );
  }, [
    ImageComp,
    lazy,
    alignX,
    alignY,
    alt,
    imageRef,
    imgSrc,
    isBasic,
    isSvg,
    load,
    objectFit,
    onLoad,
    ratio,
    sizes.breakpoints,
    sizes.maxWidth,
    src,
    supportsObjectFit,
    supportsLazyLoading,
    width,
  ]);

  const satellite = React.useMemo(() => {
    return !load ? <StyledSatellite ref={sateliteRef} /> : null;
  }, [load, sateliteRef]);

  if (isBasic)
    return isLink ? <ImageLink {...navigationLink}>{img}</ImageLink> : img;

  return !supportsObjectFit && currentSrc ? (
    <StyledAspectWrapper
      aspect={calculateRatio(width, height, ratio)}
      className={className}
      fillWrapper={fill}
      style={{
        backgroundImage: `url(${currentSrc})`,
        backgroundPosition: getObjectPosition(alignX, alignY),
        backgroundSize: objectFit,
      }}
    >
      {satellite}
      {isLink ? <ImageLink {...navigationLink}>{img}</ImageLink> : img}
      {conditionalSpinner}
      {children}
    </StyledAspectWrapper>
  ) : (
    <StyledAspectWrapper
      aspect={calculateRatio(width, height, ratio)}
      className={className}
      fillWrapper={fill}
    >
      {satellite}
      {isLink ? <ImageLink {...navigationLink}>{img}</ImageLink> : img}
      {conditionalSpinner}
      {children}
    </StyledAspectWrapper>
  );
};

Image.displayName = "Image";

export default Image;
