import * as React from "react";
import styled, { css } from "styled-components";

import { DimensionNames } from "@/styles/media";
import { clearMargin } from "@/styles/style-helpers";

import config, { createQuery } from "./grid-config";

type Props = {
  flex?: boolean;
  tag?: string;
  clearChildMargin?: boolean;
  children: React.ReactNode;
  outerMargin?: number;
  xsMargin?: number;
  smMargin?: number;
  mdMargin?: number;
  lgMargin?: number;
  hdMargin?: number;
  width?: DimensionNames;
};

const outerMargin = (key: DimensionNames | boolean, value?: number) => {
  if (process.env.CMS) {
    return createQuery(
      key,
      css`
        padding-right: 0;
        padding-left: 0;
      `
    );
  }
  if (typeof value === "number") {
    return createQuery(
      key,
      css`
        padding-left: ${value * 10}px;
        padding-right: ${value * 10}px;
      `
    );
  }

  return null;
};

/**
 * Use <Grid> to contain and center items.
 * You can apply an outer margin (actually padding in CSS), to create spacing to the edge.
 * The margin value is multiplied by the scale of 10,
 */

type StyledProps = Props & {
  containerWidth?: DimensionNames;
  flexBox?: boolean;
  as?: string;
  clearChildMargin?: boolean;
};

const StyledGrid = styled.div<StyledProps>`
  margin: 0 auto;
  max-width: ${(p) =>
    (p.containerWidth ? config.container[p.containerWidth] : null) ||
    config.container.hd}em;
  ${(p) =>
    p.flexBox &&
    css`
      display: flex;
      flex-wrap: wrap;
    `};
  ${(p: Props) => clearMargin(!!p.clearChildMargin)};
  ${(p: Props) => outerMargin(true, p.outerMargin)}
  ${(p: Props) => outerMargin("xs", p.xsMargin)}
  ${(p: Props) => outerMargin("sm", p.smMargin)}
  ${(p: Props) => outerMargin("md", p.mdMargin)}
  ${(p: Props) => outerMargin("lg", p.lgMargin)}
  ${(p: Props) => outerMargin("hd", p.hdMargin)}
` as React.FunctionComponent<StyledProps>;

const Grid = ({ tag, width, flex, ...rest }: Props) => {
  return (
    <StyledGrid
      as={tag}
      containerWidth={width}
      flexBox={flex}
      {...rest}
      data-testid={"grid"}
    />
  );
};

Grid.displayName = "Grid";

export default Grid;
