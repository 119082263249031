import * as React from "react";

import Blockquote from "@/components/Blockquote/Blockquote";

type Props = {
  children?: React.ReactNode;
};

function BlockquoteRenderer({ children }: Props) {
  const childs = React.Children.toArray(children);
  let inner = children;

  /* If the Paragraph just contains an image, break it out of the paragraph so we can render the figure at the root */
  if (
    childs.length === 1 &&
    React.isValidElement(childs[0]) &&
    childs[0].type &&
    (childs[0] as JSX.Element).type.displayName &&
    (childs[0] as JSX.Element).type.displayName.includes("Paragraph")
  ) {
    inner = childs[0].props.children;
  }

  return <Blockquote>{inner}</Blockquote>;
}

BlockquoteRenderer.displayName = "BlockquoteRenderer";
BlockquoteRenderer.defaultProps = {};

export default BlockquoteRenderer;
