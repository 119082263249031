const subscribers = new Map();

/**
 * Subscribe to be notified if an action is triggered
 * @param action {string}
 * @param callback {function}
 */
export function subscribe(
  action: string,
  callback: (payload: boolean, action: string) => void
) {
  if (subscribers.has(action)) {
    const set = subscribers.get(action);
    if (set) {
      set.add(callback);
    }
  } else {
    subscribers.set(action, new Set([callback]));
  }
}

/**
 * Unsubscribe from an action you started watching
 * @param action {string}
 * @param callback {function}
 */
export function unsubscribe(
  action: string,
  callback: (
    payload: {
      [key: string]: any;
    },
    action: string
  ) => void
) {
  const set = subscribers.get(action);
  if (set) set.delete(callback);
}

/**
 * Dispatch an action to notify all subscribers
 * @param action {string}
 * @param payload {*}
 * @return boolean Returns true if the event was successfully dispatched to subscribers.
 */
export function dispatch(
  action: string,
  payload:
    | {
        [key: string]: any;
      }
    | boolean = {}
): boolean {
  const targets = subscribers.get(action);
  if (targets) {
    targets.forEach(
      (
        cb: (payload: { [key: string]: any } | boolean, action: string) => any
      ) => cb(payload, action)
    );

    return true;
  }

  return false;
}

/**
 * Remove all subscribers
 */
export function destroy() {
  subscribers.clear();
}
