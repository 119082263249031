import { useAtom } from "jotai";
import React from "react";

import { useRouter } from "@/App/Router";
import { authAtom } from "@/store";
import { DawaItemDataType } from "@/types/DawaItemDataType";
import { usePublicAssessment } from "@/utils/hooks/usePublicAssessment";

import { ObjectionProvider } from "./contexts/ObjectionContext";
import { PropertyDataContextProvider } from "./contexts/PropertyDataContext";

const Providers = ({ children }: any) => {
  const [{ user }] = useAtom(authAtom);
  const { location } = useRouter();
  const dawa: DawaItemDataType = location.state ?? {};

  //if a result contains documentType, perform a global search else look up a public property.
  usePublicAssessment(
    !dawa?.documentType
      ? dawa?.id
        ? dawa?.id
        : dawa?.bfenummer?.toString()
      : undefined,
    dawa?.id ? "address" : "bfe"
  );

  if (user) {
    return (
      <ObjectionProvider>
        <PropertyDataContextProvider>{children}</PropertyDataContextProvider>
      </ObjectionProvider>
    );
  }

  return children;
};

Providers.displayName = "Providers";

export default Providers;
