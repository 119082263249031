import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import * as React from "react";
import styled from "styled-components";

import { color } from "../../styles/theme";

type Props = {
  children?: React.ReactNode;
  className?: string;
  checked?: boolean;
  name: string;
  value: string;
  onFocus?: (...args: Array<any>) => any;
  onBlur?: (...args: Array<any>) => any;
  onChange?: (...args: Array<any>) => any;
  marginBottom?: boolean;
};

const StyledLabel = styled(motion.label)<{ marginBottom?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 26px;
  word-wrap: break-word;
  margin-bottom: ${(p) => p.marginBottom && "10px"};
  cursor: pointer;
` as React.FunctionComponent<{
  variants: Variants;
  marginBottom?: boolean;
  className?: string;
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
}>;

const StyledContent = styled.span`
  padding-left: 12px;
  flex: 0 1 auto;
  font-size: 1rem;
  line-height: 1.63;
  color: ${color.blue};
`;

const StyledDotSvg = styled(motion.svg)<{ hover?: boolean }>`
  flex: 0 0 18px;
  cursor: pointer;
`;

const StyledHiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &:focus + ${StyledDotSvg} {
    outline-offset: 4px;
    outline: 2px solid currentColor;
  }

  .js-focus-visible & :focus:not([data-focus-visible-added]) + ${StyledDotSvg} {
    outline: none;
  }
`;

const RadioButton = ({
  className,
  marginBottom,
  checked,
  name,
  value,
  children,
  ...rest
}: Props) => {
  const [hover, setHover] = React.useState<boolean>(false);

  const variants: Variants = {
    checked: { r: 6, stroke: color.blue, strokeWidth: 5 },
    unchecked: { r: 8, strokeWidth: 1, stroke: "#d0d0d8" },
    hover: { r: 8, strokeWidth: 2, stroke: "#acacb8" },
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLInputElement>) => {
    e.type === "mousedown" && e.detail > 1 // Prevent double click
      ? e.preventDefault()
      : setHover(e.type === "mouseenter");
  };

  return (
    <StyledLabel
      className={className}
      marginBottom={marginBottom}
      onMouseDown={handleMouseEvent}
      onMouseEnter={handleMouseEvent}
      onMouseLeave={handleMouseEvent}
      variants={variants}
    >
      <StyledHiddenInput
        checked={checked}
        name={name}
        type="radio"
        value={value}
        {...rest}
      />
      <StyledDotSvg
        animate={checked ? "checked" : "unchecked"}
        height="18"
        hover={hover}
        initial={checked ? "checked" : "unchecked"}
        viewBox="0 0 18 18"
        width="18"
      >
        <motion.circle
          animate={checked ? "checked" : "unchecked"}
          cx="9"
          cy="9"
          fill={color.white}
          initial={checked ? "checked" : "unchecked"}
          variants={variants}
          whileHover="hover"
        />
      </StyledDotSvg>
      <StyledContent>{children}</StyledContent>
    </StyledLabel>
  );
};

RadioButton.displayName = "RadioButton";
RadioButton.defaultProps = {};

export default RadioButton;
