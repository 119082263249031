import { Validator } from "jsonschema";

import schema from "./schema.json";

// json schema validation
// https://iciskm.atlassian.net/wiki/spaces/VP/pages/2416443730/Indsigelse+indhold+schema+R.5.1

export default (data) => {
  try {
    const validation = new Validator();

    const res = validation.validate(data, schema);

    if (!res.valid) {
      console.error("Invalid JSON schema for 'indhold':", res.errors);

      return false;
    }
  } catch (error) {
    console.error(error);
  }

  return true;
};
