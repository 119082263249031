import { parse } from "url";

export function getCurrentPageURL() {
  const location = global.location;
  if (!location) return "";

  return `${location.protocol}//${location.host}${location.pathname}${
    location.search ? location.search : ""
  }`;
}

/**
 * Takes a url and adds a redirectUrl to it.
 * By default it will be the current page, but you can give it a different url
 **/
export function generateRedirectUrl(url: string, redirectUrl?: string) {
  if (!url) {
    throw new Error("Tried to generated a redirect URL using a missing URL");
  }
  const split = url.split("?");
  if (process.env.NODE_ENV === "development" || process.env.GRAPHQL_MOCK) {
    redirectUrl = undefined;
  }
  const searchParams = new URLSearchParams(
    split.length > 1 ? split[1] : undefined
  );

  searchParams.set("redirectUrl", redirectUrl || getCurrentPageURL());

  return `${split[0]}?${searchParams.toString()}`;
}

type ParameterValueType = number | string;

const valParser = (val: string) => (isNaN(Number(val)) ? val : Number(val));

/**
 * Convert a URLSearchParams instance to an object with values parsed as numbers if possible
 * Important! This does not handle parameters the occurs multiple times in the search.
 * It only returns the first instance og the key
 */
export function objectFromURLSearchParams(params: URLSearchParams): {
  [key: string]: ParameterValueType;
} {
  const ret: {
    [key: string]: ParameterValueType;
  } = {};

  // ? .keys() should be part of the default ts lib for iterables
  // @ts-ignore
  for (const key of params.keys()) {
    const value = params.get(key);

    value && (ret[key] = valParser(value));
  }

  return ret;
}

/** Unifies how we construct URLs in the frontend vs how we receive them from Umbraco */
export function normalizePathname(
  pathname: string | null = "/",
  addTrailingSlash = false
) {
  if (!pathname) return pathname;

  if (addTrailingSlash) {
    if (!pathname.endsWith("/")) {
      return pathname + "/";
    }
  } else if (pathname !== "/" && pathname.endsWith("/")) {
    return pathname.substr(0, pathname.length - 1);
  }

  return pathname;
}

export function normalizeUrl(url?: string, addTrailingSlash = false) {
  const parsed = parse(url || "/", false);

  parsed.pathname = normalizePathname(parsed.pathname, addTrailingSlash);

  // Remove standalone '#' - Leftover from Umbraco
  if (parsed.hash === "#") parsed.hash = "";

  return parsed;
}
