import * as React from "react";
import styled from "styled-components";

import { ActionType } from "@/components/Modals/ActionModal";

import FocusTrap from "../FocusTrap/FocusTrap";
import Portal from "../Portal/Portal";

export type BaseModalProps = {
  ariaLabel?: string;
  onRequestClose?: () => void;
  className?: string;
  focusSelector?: string;
  isOpen: boolean;
  children: React.ReactNode;
  callback?: () => void;
  actions?: Array<ActionType>;
};

const StyledWrapper = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: ${(p) => !p.isOpen && "none"};
  content-visibility: auto;
`;

/**
 * The BaseModal creates a wrapper that handles opening and closing the modal while managing focus.
 **/
const BaseModal = ({
  isOpen = false,
  children,
  focusSelector,
  className,
  onRequestClose,
  ariaLabel,
  ...rest
}: BaseModalProps) => {
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && onRequestClose) {
        onRequestClose();
      }
    },
    [onRequestClose]
  );

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isOpen, handleKeyDown]);

  return (
    <Portal>
      <FocusTrap active={isOpen} onRequestClose={onRequestClose}>
        <StyledWrapper
          aria-hidden={isOpen ? "false" : "true"}
          aria-label={ariaLabel}
          className={className}
          data-testid="modal"
          isOpen={isOpen}
          role="dialog"
          {...rest}
        >
          {children}
        </StyledWrapper>
      </FocusTrap>
    </Portal>
  );
};
BaseModal.displayName = "Modal";

export default BaseModal;
