import useMedia from "@charlietango/use-media";
import { rem } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CloseXIcon from "@/icons/CloseXIcon";
import { breakpoints } from "@/styles/media";
import { color } from "@/styles/theme";

import IconButton from "../../IconButton/IconButton";

type Props = {
  heightResized?: boolean;
  isDesktopWindow?: boolean;
  onClick?: () => void;
};

const StyledCloseBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: ${color.white};

  @media only screen and (min-width: ${breakpoints.sm}em) and (min-height: 516px) {
    position: absolute;
    right: 0;
    top: -63px;
  }

  @media only screen and (max-width: 47.9375em),
    screen and (max-height: 515px) {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${color.white};
    align-items: center;
    padding-right: 15px;
    flex: 0 0 60px;
    height: 60px;
  }
`;

const StyledCloseLabel = styled.span`
  padding-right: 15px;
  font-weight: 600;
  font-size: ${rem(14)};
  color: ${color.altBlue};
`;

const ModalCloseBar = ({ isDesktopWindow, onClick }: Props) => {
  const isDesktop = useMedia(`(min-width: ${breakpoints.sm}em)`);
  const [t, , ready] = useTranslation("common");

  return (
    <StyledCloseBar>
      <IconButton hoverColor={color.grey} onClick={onClick}>
        {({ hover }) => (
          <>
            {(isDesktopWindow !== undefined ? isDesktopWindow : isDesktop) &&
              ready && <StyledCloseLabel>{t("close")}</StyledCloseLabel>}
            <CloseXIcon aria-hidden="true" size={"normal"} />
          </>
        )}
      </IconButton>
    </StyledCloseBar>
  );
};

ModalCloseBar.displayName = "ModalCloseBar";

export default ModalCloseBar;
