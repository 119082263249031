import * as React from "react";
import styled from "styled-components";
import { Assign } from "utility-types";

import BaseButton, { BaseButtonProps } from "@/components/Button/BaseButton";
import { durations } from "@/styles/animations";
import { color } from "@/styles/theme";
import { ButtonStateTypes } from "@/types/ButtonStateTypes";

type Props = Assign<
  BaseButtonProps,
  {
    hoverColor?: string;
    children?:
      | React.ReactNode
      | ((state: ButtonStateTypes) => React.ReactNode | null | undefined);
  }
>;

const StyledButtonStyle = styled(BaseButton)`
  background: none;
  padding: 15px;
  outline-offset: 0;
`;

const StyledInner = styled.span<{ hover?: boolean; hoverColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => (p.hover && p.hoverColor ? p.hoverColor : "currentColor")};
  transition: color ${durations.fast} ease-out;
`;

/**
 * Wrapper that creates a button that centers an icon (or similar item)
 **/
function IconButton({ children, hoverColor, ...rest }: Props) {
  return (
    <StyledButtonStyle {...rest}>
      {(buttonState) => (
        <StyledInner
          data-hover={buttonState.hover}
          hover={buttonState.hover}
          hoverColor={hoverColor}
        >
          {typeof children === "function" ? children(buttonState) : children}
        </StyledInner>
      )}
    </StyledButtonStyle>
  );
}

IconButton.displayName = "IconButton";
IconButton.defaultProps = {
  hoverColor: color.altBlue,
};

export default IconButton;
