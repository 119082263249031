export const ACCESS_DENIED = "ACCESS_DENIED";
export const HELPPANEL_DISABLED = "HELPPANEL_DISABLED";
export const HELPPANEL_ENABLED = "HELPPANEL_ENABLED";
export const HELPPANEL_SHOW_ITEM = "HELPPANEL_SHOW_ITEM";
export const LOCK_STATUS = "LOCK_STATUS";
export const RELAUNCH_COOKIE_BANNER = "RELAUNCH_COOKIE_BANNER";
export const HANDLE_OPEN_PANEL = "HANDLE_OPEN_PANEL";
export const HANDLE_CLOSE_PANEL = "HANDLE_CLOSE_PANEL";
export const HANDLE_SUBSCRIBE_DATA = "HANDLE_SUBSCRIBE_DATA";
export const SET_ACTIVE_QUERY = "SET_ACTIVE_QUERY";
export const HANDLE_MINIMIZE = "HANDLE_MINIMIZE";
export const HANDLE_STEP = "HANDLE_STEP";
export const HANDLE_SEARCH_BACKSTEP = "HANDLE_SEARCH_BACKSTEP";
export const HANDLE_SEARCH_STEP = "HANDLE_SEARCH_STEP";
export const SET_SEARCH_ACTIVE = "SET_SEARCH_ACTIVE";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
