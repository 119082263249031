import { rem } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Grid from "@/components/Grid/Grid";
import Richtext from "@/components/Richtext/Richtext";
import ExclamationMarkIcon from "@/icons/ExclamationMarkIcon";
import { color } from "@/styles/theme";

const StyledContainer = styled.div<{ border?: boolean }>`
  padding: 30px 0;
  border-top: ${(p) => (p.border ? `1px solid ${color.borderGrey}` : "none")};
  border-bottom: ${(p) =>
    p.border ? `1px solid ${color.borderGrey}` : "none"};
`;

const StyledTitle = styled.div`
  font-size: ${rem(20)};
  color: ${color.altBlue};
  font-weight: 600;
`;

const StyledContent = styled.div`
  margin: 2rem 0;
  max-width: 550px;
`;

function FetchError() {
  const [t, , ready] = useTranslation("common");

  if (!ready) return null;

  return (
    <StyledContainer border>
      <Grid width="sm">
        <StyledTitle>
          {t("dataErrorTitle")} <ExclamationMarkIcon />
        </StyledTitle>

        <StyledContent>
          <Richtext source={t("dataErrorMessage")} />
        </StyledContent>
      </Grid>
    </StyledContainer>
  );
}

FetchError.displayName = "FetchError";

FetchError.defaultProps = {};

export default FetchError;
