import useMedia from "@charlietango/use-media";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import styled from "styled-components";

import { baseDurations } from "../../styles/animations";
import { Fill } from "../../styles/blocks";
import { breakpoints, mediaOnly } from "../../styles/media";
import { color } from "../../styles/theme";
import Grid from "../Grid/Grid";
import config from "../Grid/grid-config";
import BaseModal from "./BaseModal";
import ModalCloseBar from "./private/ModalCloseBar";

export type ModalProps = {
  ariaLabel: string;
  onRequestClose?: () => void;
  onClosed?: () => void;
  focusOnButton?: boolean;
  isOpen: boolean;
  children: React.ReactNode;
  closeOnBackdropClicked?: boolean;
  hideModalCloseBar?: boolean;
  heightBreakPoint?: number;
  showPip?: boolean;
};

const StyledBackdrop = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #d0d0d8;
`;

const StyledContent = styled(motion.div)<{
  heightBreakPoint?: number;
  hideModalCloseBar: boolean;
  showPip?: boolean;
}>`
  position: relative;
  max-width: ${config.container.hd}em;

  ${(p) =>
    p.heightBreakPoint
      ? `
    @media screen and (height <= ${p.heightBreakPoint}px) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  `
      : null}

  ${mediaOnly.xs`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  `};

  ${(p) =>
    p.showPip
      ? `
  &:before {
    content: "";
    position: absolute;
    top: 0;
    display: inline-block;
    width: 60px;
    height: 16px;
    background-color: ${color.altBlue};

    @media only screen and (min-width: ${breakpoints.xs}em) {
      left: 20px;
    }

    @media only screen and (min-width: ${breakpoints.xsm}em) {
    ${
      p.heightBreakPoint &&
      `@media only screen and (max-height: 515px) {
        left: 20px;
      }`
    }
      left: 20px;
    }
   
    @media only screen and (min-width: ${breakpoints.sm}em) {
      ${
        p.heightBreakPoint &&
        `@media only screen and (max-height: 515px) {
        left: 40px;
      }`
      }
      left: 80px;
    }
    
    @media only screen and (min-width: ${breakpoints.md}em) {
      ${
        p.heightBreakPoint &&
        `@media only screen and (max-height: 515px) {
        left: 40px;
      }`
      }
      left: 100px;
    }
    z-index: 2;
  }`
      : null}
`;

const StyledWrapper = styled(BaseModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalContainer = styled.div`
  position: relative;
  background-color: ${color.white};
  box-shadow: 0 15px 60px 0 rgba(20, 20, 60, 0.1);
  max-height: calc(100vh - 160px);
`;

const StyledMobileFill = styled(Fill)`
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

/**
 * Modal extends the BaseModal by making some decisions on the layout.
 * It presents the content inside a card, with a closebar on top.
 **/
const Modal = ({
  ariaLabel,
  onClosed,
  isOpen,
  children,
  onRequestClose,
  focusOnButton,
  closeOnBackdropClicked = true,
  hideModalCloseBar,
  heightBreakPoint,
  showPip,
}: ModalProps) => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isDesktop = useMedia(`(min-width: ${breakpoints.sm}em)`);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    function handleWindowResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const showDesktopModal = React.useMemo(() => {
    const isDesktopHeight = heightBreakPoint
      ? windowSize.height > heightBreakPoint
      : true;

    return isDesktopHeight && isDesktop;
  }, [heightBreakPoint, isDesktop, windowSize.height]);

  const transition = {
    type: "tween",
    duration: baseDurations.medium,
  };

  return (
    <AnimatePresence onExitComplete={onClosed}>
      {isOpen ? (
        <StyledWrapper
          ariaLabel={ariaLabel}
          focusSelector={focusOnButton ? "button" : undefined}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
        >
          {showDesktopModal ? (
            <StyledBackdrop
              key={"backdrop"}
              animate={{ opacity: 0.8 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={closeOnBackdropClicked ? onRequestClose : () => {}}
              role={closeOnBackdropClicked ? "button" : undefined}
              transition={transition}
            />
          ) : null}
          <StyledContent
            key={"content"}
            animate={{ opacity: 1, transform: `translateY(0px)` }}
            exit={{ opacity: 0, transform: `translateY(4px)` }}
            heightBreakPoint={heightBreakPoint}
            hideModalCloseBar={!!hideModalCloseBar}
            initial={{ opacity: 0, transform: `translateY(4px)` }}
            showPip={showPip}
            transition={transition}
          >
            {showDesktopModal ? (
              <Grid
                clearChildMargin={false}
                mdMargin={6}
                outerMargin={0}
                smMargin={4}
              >
                <StyledModalContainer>
                  {!hideModalCloseBar && (
                    <ModalCloseBar
                      isDesktopWindow={showDesktopModal}
                      onClick={onRequestClose}
                    />
                  )}
                  {children}
                </StyledModalContainer>
              </Grid>
            ) : (
              <StyledMobileFill>
                {!hideModalCloseBar && (
                  <ModalCloseBar
                    isDesktopWindow={showDesktopModal}
                    onClick={onRequestClose}
                  />
                )}
                {children}
              </StyledMobileFill>
            )}
          </StyledContent>
        </StyledWrapper>
      ) : null}
    </AnimatePresence>
  );
};

export default Modal;
