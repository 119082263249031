import * as React from "react";
import styled from "styled-components";

import { FontSizes } from "../../styles/fonts";
import { mediaOnly } from "../../styles/media";
import { getFontSize, getLineHeight } from "../../styles/style-helpers";
import { Theme } from "../../styles/theme";
import { color } from "../../styles/theme";

type Props = {
  children?: React.ReactNode;
  marginBottom?: boolean;
  truncate?: boolean;

  /** Don't split the children into multiple paragraphs */
  noBreaks?: boolean;

  area?: string;

  /** Size corresponding to the body styles in the styleguide */
  size: FontSizes;
  weight?: 400 | 500 | 600 | 700;

  /** Limit the width of the paragraph to a readable 550px*/
  maxWidth?: boolean;

  /** The HTML tag to output - Defaults to <p> */
  tag?: string;
  theme?: Theme;
  color?: string;
};

type InnerProps = Props & { "data-color"?: string; as?: string };

const StyledParagraph = styled.p<InnerProps>`
  font-size: ${(p) => getFontSize(p.size)};
  line-height: ${(p) => getLineHeight(p.size)};
  ${(p) =>
    p.size === "large" &&
    mediaOnly.xs`
    font-size: 1rem
  `};
  text-align: left;
  margin-top: 0;
  margin-bottom: ${(props) => {
    if (props.marginBottom)
      return props.area === "article" ? "0.625rem" : "10px";
    else return 0;
  }};
  font-weight: ${(props) => props.weight || 400};
  max-width: ${(props) =>
    props.maxWidth
      ? props.area === "article"
        ? "640px"
        : "550px"
      : undefined};
  color: ${(props) =>
    props["data-color"]
      ? props["data-color"]
      : props.theme && props.theme.style === "dark"
      ? color.lightGrey
      : "currentColor"};
` as React.FunctionComponent<InnerProps>;

const Paragraph = ({ children, noBreaks, tag, color, ...rest }: Props) => {
  if (React.Children.count(children) > 1 && !noBreaks) {
    const last = React.Children.count(children) - 1;

    return (
      <>
        {React.Children.map(children, (child, index) => (
          <StyledParagraph
            as={tag}
            data-color={color}
            marginBottom={index < last}
            {...rest}
            children={child}
          />
        ))}
      </>
    );
  }

  return (
    <StyledParagraph
      as={tag}
      data-color={color}
      {...rest}
      children={children}
    />
  );
};

Paragraph.displayName = "Paragraph";
Paragraph.defaultProps = {
  size: "normal",
};

export default Paragraph;
