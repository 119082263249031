import parse, { DOMNode, domToReact, Element } from "html-react-parser";
import * as React from "react";
import styled from "styled-components";

import Anchorlink from "@/components/Richtext/renderers/Anchorlink/Anchorlink";
import BlockquoteRenderer from "@/components/Richtext/renderers/BlockquoteRenderer/BlockquoteRenderer";
import DataPointValue from "@/components/Richtext/renderers/DataPointValue/DataPointValue";
import HeaderRenderer from "@/components/Richtext/renderers/HeaderRenderer/HeaderRenderer";
import HelpLink, {
  StyledHelpLink,
} from "@/components/Richtext/renderers/HelpLink/HelpLink";
import ImageRenderer from "@/components/Richtext/renderers/ImageRenderer/ImageRenderer";
import LinkRenderer from "@/components/Richtext/renderers/LinkRenderer/LinkRenderer";
import ListRenderer from "@/components/Richtext/renderers/ListRenderer/ListRenderer";
import ParagraphRenderer from "@/components/Richtext/renderers/ParagraphRenderer/ParagraphRenderer";
import TableRenderer from "@/components/Richtext/renderers/TableRenderer/TableRenderer";
import Underline from "@/components/Richtext/renderers/Underline/Underline";
import Vurderingstermin from "@/components/Richtext/renderers/Vurderingstermin/Vurderingstermin";
import { RichtextdatapointsFragment } from "@/graphql/__generated__/privateTypes";
import { FontSizes } from "@/styles/fonts";
import {
  clearMargin,
  getFontSize,
  getLineHeight,
} from "@/styles/style-helpers";

export type blockMargin =
  | "small"
  | "large"
  | "normal"
  | "xlarge"
  | "none"
  | undefined;

export type RichtextProps = {
  source?: string | null | undefined;
  className?: string;

  /** How much margin should be used between blocks. This will affect headers and images. Default is `large`, which matches the intended margin between elements */
  blockMargin?: blockMargin;
  size?: FontSizes;
  maxWidth?: boolean;
  isAccordion?: boolean;
  datapoints?: RichtextdatapointsFragment;
  // Added as own property, as maxWidth previously only referred to the title and this might break some layouts
  paragraphMaxWidth?: boolean;
};

const StyledWrapper = styled.div<{ size?: FontSizes }>`
  font-size: ${(p) => (p.size ? getFontSize(p.size) : undefined)};
  line-height: ${(p) => (p.size ? getLineHeight(p.size) : undefined)};
  /* this one is applicable only when there is one element in the "source" */
  ${clearMargin()}
  /* when there is more children Markdown wraps them in a div and the "> first-child" is applicable */
  > :first-child {
    ${clearMargin()};
  }

  ${StyledHelpLink} {
    margin-top: 20px;
  }

  ${StyledHelpLink} + ${StyledHelpLink} {
    margin-top: 20px;
  }

  ${StyledHelpLink} ~ * {
    margin-top: 10px;
  }
`;

const Richtext = ({
  source,
  className,
  blockMargin,
  size,
  isAccordion,
  maxWidth = false,
  paragraphMaxWidth = true,
  datapoints,
}: RichtextProps) => {
  if (!source) return null;

  const options = {
    replace(domNode) {
      if (domNode instanceof Element) {
        const children = domToReact(domNode.children as DOMNode[], options);
        const attributes = domNode?.attribs?.["data-attributes"];

        switch (domNode.tagName) {
          case "h1":
            return HeaderRenderer({
              children,
              level: 1,
              marginTop: blockMargin,
            });
          case "h2":
            return HeaderRenderer({
              children,
              level: 2,
              marginTop: blockMargin,
              maxWidth: maxWidth,
            });
          case "h3":
            return HeaderRenderer({
              children,
              level: 3,
              marginTop: "small",
            });
          case "h4":
            return HeaderRenderer({
              children,
              level: 4,
              marginTop: blockMargin,
            });
          case "h5":
            return HeaderRenderer({
              children,
              level: 5,
              marginTop: blockMargin,
            });
          case "h6":
            return HeaderRenderer({
              children,
              level: 6,
              marginTop: blockMargin,
            });
          case "img":
            return ImageRenderer({
              margin: blockMargin,
              src: domNode.attribs.src,
              ...domNode.attribs,
            });
          case "a":
            return LinkRenderer({
              children,
              href: domNode.attribs.href,
              ...domNode.attribs,
            });
          case "p":
            return ParagraphRenderer({
              children,
              size,
              isAccordion,
              maxWidth: paragraphMaxWidth,
            });
          case "blockquote":
            return BlockquoteRenderer({
              children,
            });
          case "ol":
            return ListRenderer({
              children,
              ordered: true,
              size,
            });
          case "ul":
            return ListRenderer({
              children,
              ordered: false,
              size,
            });
          case "custom-helplink":
            return HelpLink({
              attributes,
            });
          case "vurderingstermin": //????
            return Vurderingstermin();
          case "custom-underline":
            return Underline({
              attributes,
            });
          case "custom-datapointvalue":
            return DataPointValue({
              attributes,
              datapoints,
            });
          case "custom-anchorlink":
            return Anchorlink({
              attributes,
              isAccordion,
            });
          case "custom-image":
            return ImageRenderer({
              src: domNode.attribs.src,
              attributes,
            });
          case "table":
            return TableRenderer({
              children: children,
            });
          default:
            break;
        }
      } else {
        //Handels cases where Richtext is used, but the input is not an html element (eg. textArea in Umbraco)
        if (domNode.parent === null && domNode.type === "text") {
          const splitData = (domNode.data as string).split("\n");

          return (
            <>
              {splitData
                .filter((val) => val !== "")
                .map((data, index) =>
                  ParagraphRenderer({
                    children: data,
                    size,
                    maxWidth,
                    key: index + data.slice(0, 3),
                  })
                )}
            </>
          );
        }
      }
    },
  };

  const parsedChildren = parse(source, options);

  return (
    <StyledWrapper className={className} size={size}>
      {parsedChildren}
    </StyledWrapper>
  );
};

Richtext.displayName = "Richtext";
Richtext.defaultProps = {
  blockMargin: "large",
  overrides: {},
};

export default React.memo(Richtext);
