import * as React from "react";
import styled from "styled-components";
import { Diff } from "utility-types";

import { FontSizes } from "@/styles/fonts";
import { getFontSize } from "@/styles/style-helpers";

type Props = {
  children?: React.ReactNode;
  ordered?: boolean;
  size?: FontSizes;
};

type StyledProps = Diff<
  Props,
  { children?: React.ReactNode; ordered?: boolean }
>;

const StyledBase = styled.ul<StyledProps>`
  font-size: ${(p) => (p.size ? getFontSize(p.size) : "1rem")};
  line-height: 1.5;
  padding: 0 0 0 1.5rem;
  margin-bottom: 2.5rem;

  > li {
    margin-bottom: 18px;
    position: relative;
  }

  li > ul,
  li > ol {
    margin: 0.5rem 0 0;
  }
`;

const StyledUnorderedList = styled(StyledBase)`
  li::before {
    left: -1em;
  }
`;

const StyledOrderedList = styled(StyledBase.withComponent("ol"))`
  li::before {
    left: -1em;
  }
`;

function List({ children, ordered, ...props }: Props) {
  const Comp = ordered ? StyledOrderedList : StyledUnorderedList;

  return <Comp {...props}>{children}</Comp>;
}

List.displayName = "List";
List.defaultProps = {};

export default List;
