import { filter, find, pathOr, pipe, sort, tap } from "ramda";

import { PAYLOAD_TYPES } from "@/App/constants";
import {
  GetIndsendelserQuery as IndsendelserDataType,
  GetIndsendelserQuery_vurderingsejendom_Vurderingsejendom_vurderingssager_Vurderingssag_indsendelser_Indsendelse_bilag_Bilag as BilagType,
  Indsendelse as CurrentDraftType,
} from "@/graphql/__generated__/privateTypes";
import isValidJson from "@/modules/ContactAndObjection/Objection/ObjectionForm/private/helperFiles/validateJson";

import { PayloadFormSchema } from "../../types";
import { FilePayload } from "./types";

export const getCurrentObjectionPayload = (
  currentDraft:
    | CurrentDraftType
    | null
    | undefined
    | {
        [key: string]: any;
      },
  formCode: string | undefined,
  tapFn: (...args: Array<any>) => any = () => {}
): PayloadFormSchema | undefined => {
  return pipe(
    tap(tapFn),
    pathOr("{}", ["indhold"]), // return the actual payload string
    JSON.parse, // Parse to object
    pathOr([], ["indsigelser"]), // find indsigelser
    find(
      (objection: PayloadFormSchema) => objection.indsigelseskode === formCode
    ) // find the correct indsigelse
  )(currentDraft);
};

const valuationYearSort = (a, b) => {
  return a.vurderingsaar > b.vurderingsaar
    ? -1
    : a.vurderingsaar < b.vurderingsaar
    ? 1
    : 0;
};

export const getLatestCase = (data: IndsendelserDataType, ejendomsid: number) =>
  pipe(
    pathOr([], ["vurderingsejendom", "vurderingssager"]),
    sort(valuationYearSort),
    find((valuationCase) => valuationCase.vurderingsejendom_id === ejendomsid)
  )(data);

export const getCurrentDraft = (
  data: IndsendelserDataType,
  ejendomsid: number
) => {
  const currentDraft = pipe(
    pathOr([], ["indsendelser"]),
    filter(
      (indsendelse: CurrentDraftType) => indsendelse.indsendelse_id !== null
    ),
    find(
      (indsendelse: CurrentDraftType) =>
        indsendelse.type === PAYLOAD_TYPES.indsigelse &&
        indsendelse.tilstand === "kladde"
    )
  )(getLatestCase(data, ejendomsid));

  // validate indhold schema from Orange
  if (currentDraft && currentDraft.indhold) {
    const data = JSON.parse(currentDraft?.indhold);
    isValidJson(data);
  }

  return currentDraft;
};

export const filterFilesForDuplicates = (
  files: Array<FilePayload>
): Array<FilePayload> => {
  return files.reduce((acc, current) => {
    const x = acc.find((item: FilePayload) => item.file_id === current.file_id);
    if (!x) {
      // @ts-ignore
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

export const filterForPreviousPendingFiles = (
  bilagFiles: Array<BilagType> = []
): Array<BilagType> => {
  if (!bilagFiles || bilagFiles.length === 0) {
    return [];
  }

  return bilagFiles.filter((bilag: BilagType) => bilag.tilstand === "afventer");
};

//compares payload files with bilag files, if they are actually uploaded in the server
export const filterForApprovedFiles = (
  payloadFiles: Array<FilePayload> = [],
  bilagFiles: Array<BilagType> = []
): Array<FilePayload> => {
  const godkendtBilagFiles = bilagFiles.filter(
    (bilag) => bilag.tilstand === "godkendt"
  );

  return payloadFiles.filter((file: FilePayload) => {
    return godkendtBilagFiles
      .filter((x): x is BilagType => !!x)
      .some((bilag: BilagType) => {
        if (bilag.bilag_id) {
          return bilag.bilag_id.toString() === file.file_id;
        }

        return false;
      });
  });
};
