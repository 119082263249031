import * as React from "react";
import styled from "styled-components";

import { AreaConsumer } from "@/components/AreaContext/AreaContext";
import CtaLink from "@/components/CtaLink/CtaLink";
import Paragraph from "@/components/Paragraph/Paragraph";
import { FontSizes } from "@/styles/fonts";

type Props = {
  children?: React.ReactNode;
  size?: FontSizes;
  isAccordion?: boolean;
  maxWidth?: boolean;
  key?: string;
};

const StyledLinkWrapper = styled.div`
  margin-bottom: 1rem;
`;

const isImageRenderer = (item) =>
  item.type && item.type.displayName === "ImageRenderer";

const isLinkRenderer = (item) =>
  item.type && item.type.displayName === "LinkRenderer";

function ParagraphRenderer({
  children,
  size,
  isAccordion,
  maxWidth = true,
  key,
}: Props) {
  const childs = React.Children.toArray(children).reduce(
    (elements: Array<any>, element, index) => {
      if (isImageRenderer(element)) {
        // Images are always rendered in their own "paragraph"
        elements.push(element);
      } else {
        // All other children are added to the list
        if (!elements.length || !Array.isArray(elements[elements.length - 1])) {
          (elements as Array<any>).push([]);
        }
        elements[elements.length - 1].push(element);
      }

      return elements;
    },
    []
  );

  const tracking = {
    section: isAccordion ? "accordion" : "article",
    eventName: isAccordion ? "accordionLinkClick" : "articleLinkClick",
  };

  return (
    <React.Fragment key={key}>
      {(childs as Array<any>).map((child, index) => {
        if (!Array.isArray(child) && isImageRenderer(child)) {
          return child;
        }

        if (child.every(isLinkRenderer)) {
          // If all the children are links, renderer them as CTA Links
          return child.map(({ props }, key) => {
            const name =
              typeof props.children === "string"
                ? props.children
                : Array.isArray(props.children)
                ? props.children[0]
                : props.title
                ? props.title
                : props.href;

            return (
              <StyledLinkWrapper key={`${index}_${key}`}>
                <CtaLink {...props} tracking={{ ...tracking, name }} />
              </StyledLinkWrapper>
            );
          });
        }

        return (
          <AreaConsumer key={index}>
            {(area) => (
              <Paragraph
                area={area}
                marginBottom
                maxWidth={maxWidth}
                noBreaks
                size={size}
                tag="p"
              >
                {child}
              </Paragraph>
            )}
          </AreaConsumer>
        );
      })}
    </React.Fragment>
  );
}

ParagraphRenderer.displayName = "ParagraphRenderer";
ParagraphRenderer.defaultProps = {};

export default ParagraphRenderer;
