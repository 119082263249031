import "dayjs/locale/da";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isLeapYear from "dayjs/plugin/isLeapYear";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.locale("da");
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(isLeapYear);

type AddressFields = {
  vejnavn: string | null | undefined;
  husnr: string | null | undefined;
  etage: string | null | undefined;
  doer: string | null | undefined;
};

type User = {
  fornavn: string | null | undefined;
  efternavn: string | null | undefined;
};

export function formatStreetAddress(address: AddressFields): string {
  const prepend = `${address.etage || ""} ${address.doer || ""}`.trim();

  return `${address.vejnavn || ""} ${address.husnr || ""}${
    !!prepend ? ", " + prepend : ""
  }`.trim();
}

export function formatFullName(user: User): string {
  return `${user.fornavn || ""} ${user.efternavn || ""}`.trim();
}

export function trimmedString(text: string, maxChars: number): string {
  if (text.length <= maxChars) return text;

  return text.substring(0, maxChars) + "...";
}

type AddressObject = {
  addressBetegnelse?: string;
  doerbetegnelse?: string;
  etage?: string;
  husnummer: string;
  postdistrikt: string;
  postnummer: number;
  vejnavn: string;
  supplerende_bynavn?: string;
};

type FormatConfig = {
  showCity?: boolean;
  showFloor?: boolean;
  showStreet?: boolean;
  partDelimiter?: string;
};

export function isDateInPast(value?: string | null) {
  if (value) {
    try {
      if (!dayjs(value).isValid()) {
        throw new Error("Date not valid: " + value);
      }

      return dayjs(value).locale("da").isBefore(dayjs());
    } catch (e) {}
  }

  return false;
}

export function timeFormatter(value?: string | null, format = "DD-MM-YYYY") {
  if (value) {
    try {
      if (!dayjs(value).isValid()) {
        throw new Error("Date not valid: " + value);
      }

      return dayjs(value).locale("da").format(format);
    } catch (e) {}
  }

  return undefined;
}

// It's not possible to use hooks within this function, so we assume that
// the hook must be called in the component that will use this function
// and 't' and 'locale' will be passed into this function.
export function latestUpdateFormatter(
  t: any,
  locale: string,
  value?: string | null
) {
  //'locale' comes as "language_Country" (e.g.: 'da_DK'), so we get only the language
  const lng = locale.substring(0, 2);

  dayjs.updateLocale(lng, {
    relativeTime: {
      future: "%s",
      past: "%s",
      s: t("today"),
      m: t("today"),
      mm: t("today"),
      h: t("today"),
      hh: (number, withoutSuffix, key, isFuture) => {
        return !isFuture
          ? t("today")
          : t("tomorrow", {
              value: 1 || "",
              unit_item: t("tomorrow"),
            });
      },
      d: (number, withoutSuffix, key, isFuture) => {
        return !isFuture
          ? t("yesterday")
          : t("future", {
              value: number + 1 || "",
              unit_item: t("future"),
            });
      },
      dd: (number, withoutSuffix, key, isFuture) => {
        return !isFuture
          ? `${number} ${t("days_ago")}`
          : t("future", {
              value: number + 1 || "",
              unit_item: t("future"),
            });
      },
      M: t("days_ago"),
      MM: t("days_ago"),
      y: t("years_ago", {
        value: "1",
        unit_item: t("years_ago"),
      }),
      yy: (number, withoutSuffix, key, isFuture) => {
        //"number" comes in milliseconds, so get the equivalent duration in years
        const numberOfYears = Math.floor(dayjs.duration(number).asYears());

        return !isFuture
          ? t("years_ago", {
              value: numberOfYears || "",
              unit_item: t("years_ago"),
            })
          : t("future_years", {
              value: numberOfYears || "",
              unit_item: t("future_years"),
            });
      },
    },
  });

  const isYearLeapYear = dayjs().isLeapYear();

  // Thresholds were defined based on business rules specified at the link below
  // https://iciskm.atlassian.net/wiki/spaces/VP/pages/1913487862/Forbedring+af+Mine+Ejendomme
  const thresholds = [
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: isYearLeapYear ? 366 : 365, d: "day" },
    { l: "y", r: isYearLeapYear ? 367 : 366, d: "day" },
    { l: "yy", d: "år" },
  ];

  const config = {
    thresholds: thresholds,
    rounding: Math.floor,
  };

  dayjs.extend(relativeTime, config);

  if (value) {
    try {
      if (!dayjs(value).isValid()) {
        throw new Error("Date not valid: " + value);
      }

      return dayjs(value).fromNow();
    } catch (e) {}
  }

  return undefined;
}

export const extractFormattedAddress = (
  addressObj: AddressObject,
  config: FormatConfig = {
    showStreet: true,
    showFloor: true,
    showCity: true,
    partDelimiter: ", ",
  }
): string => {
  const {
    doerbetegnelse,
    etage,
    husnummer,
    postdistrikt,
    postnummer,
    vejnavn,
  } = addressObj;

  const baseAddress = `${vejnavn} ${husnummer}`;
  const city = `${postnummer} ${postdistrikt}`;
  const floorDoor =
    typeof etage === "string" && typeof doerbetegnelse === "string"
      ? `${etage}, ${doerbetegnelse} `
      : typeof etage === "string"
      ? `${etage}`
      : typeof doerbetegnelse === "string"
      ? `${doerbetegnelse}`
      : undefined;

  const parts: string[] = [];
  if (config.showStreet) parts.push(baseAddress);
  if (config.showFloor && floorDoor) parts.push(floorDoor);
  if (config.showCity) parts.push(city);

  return `${parts.join(config.partDelimiter || ", ")}`;
};

export function formatValueWithThousandSeparator(value: string) {
  const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return formattedValue;
}

export const trimExtension = (value: string): string => {
  return value.replace(
    /\.pdf|\.png|\.jpg|\.doc|\.docx|\.xls|\.xlsx|\.odt|\.ods|\.csv/i,
    ""
  );
};
