import { rem } from "polished";
import * as React from "react";
import styled from "styled-components";

import { StyledCardDot } from "@/components/Card/Card";
import CtaLink from "@/components/CtaLink/CtaLink";
import Heading from "@/components/Heading/Heading";
import Paragraph from "@/components/Paragraph/Paragraph";
import { mediaOnly, mediaQuery } from "@/styles/media";
import { color } from "@/styles/theme";
import { heights } from "@/styles/theme";
import { ErrorPageViewModel } from "@/view-models/ErrorPageViewModel";

export type ErrorPageProps = ErrorPageViewModel;

const StyledWrapper = styled.section`
  min-height: calc(100vh - ${rem(heights.header.xs * 2)});

  ${mediaQuery.sm`
    min-height: calc(100vh - ${rem(heights.header.sm * 2)});
  `};
`;

const StyledContent = styled.div`
  position: relative;
  padding: 30px;
  margin: 0 auto;
  max-width: 720px;

  ${mediaOnly.xs`
    padding: 30px 20px;
  `};
`;

const StyledMarginHeading = styled(Heading)`
  margin-bottom: 3rem;
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > a {
    margin-bottom: 10px;
  }
`;

const ErrorPage = ({ title = "404", description, ctas }: ErrorPageProps) => {
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledCardDot bgColor={color.altBlue} />
        <StyledMarginHeading level={1} tag="h1">
          {title}
        </StyledMarginHeading>
        {description && (
          <Paragraph marginBottom={!!ctas} size="large" weight={600}>
            {description}
          </Paragraph>
        )}

        {ctas && (
          <StyledLinks>
            {ctas.map((cta, index) => (
              <CtaLink key={index} {...cta} />
            ))}
          </StyledLinks>
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

ErrorPage.displayName = "ErrorPage";

export default ErrorPage;
