import * as React from "react";
import styled from "styled-components";

import generateTrackingAttributes from "@/utils/generateTrackingAttributes";
import { normalizeUrl } from "@/utils/url-helpers";
import { LinkViewModel } from "@/view-models/LinkViewModel";

import pageLoader from "../../App/page-loader";
import { RouteContext, useRouter } from "../../App/Router";
import { focusOutline } from "../../styles/style-helpers";
import { TrackingTypes } from "../../types/TrackingTypes";

export type LinkProps = LinkViewModel & {
  underline?: boolean;
  replaceHistory?: boolean;
  onMouseEnter?: (e: Event) => void;
  onTouchStart?: (e: Event) => void;
  tracking?: TrackingTypes;
} & React.PropsWithoutRef<JSX.IntrinsicElements["a"]>;

type StyledProps = {
  underline?: boolean;
};

export function isExternalLink(href?: string, hostname?: string): boolean {
  if (!href) return false;

  return (
    !/^[#|/]/.test(href) &&
    global.location &&
    !href.includes(hostname || global.location.hostname)
  );
}

const StyledAnchor = styled.a<StyledProps>`
  color: currentColor;
  outline-offset: 1px;
  text-decoration: ${(p) => (p.underline ? "underline" : "none")};

  ${focusOutline("currentColor")};
`;

const isModifiedEvent = (event: React.MouseEvent<HTMLAnchorElement>) => {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
};

/**
 * The public API for rendering a history-aware <a>.
 */
const Link = React.forwardRef(
  (
    {
      children,
      external,
      href,
      label,
      onClick,
      onMouseEnter,
      onTouchStart,
      replaceHistory = false,
      target,
      tracking,
      underline,
      ...rest
    }: LinkProps,
    ref: any
  ) => {
    const { replace, push } = React.useContext(RouteContext);
    const [isTouched, setTouched] = React.useState<boolean>(false);
    const [ariaCurrent, setAriaCurrent] = React.useState<"page" | undefined>(
      undefined
    );
    const { location } = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (
        !external &&
        !isExternalLink(href) &&
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        target !== "_blank" && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) && // ignore clicks with modifier keys
        !!push
      ) {
        // Block the normal anchor event
        event.preventDefault();
        if (replaceHistory && replace) {
          href && replace(href);
        } else {
          href && push(href);
        }
      }
    };

    const handleTouched = (e) => {
      if (e.type === "mouseenter" && onMouseEnter) {
        onMouseEnter(e);
      }
      if (e.type === "touchstart" && onTouchStart) {
        onTouchStart(e);
      }

      if (
        !external &&
        target !== "_blank" &&
        href &&
        !isExternalLink(href) &&
        !href.includes(".html")
      ) {
        // We eagerly start preloading the next route when the user hovers over the link
        // This is a pretty basic method to discover the user intent, but it works for now
        const url = new URL(href, global.location.href);
        pageLoader.fetchHeadlessPage(url.pathname);
      }

      setTouched(true);
    };

    React.useEffect(() => {
      if (!href) return;
      const normalized = normalizeUrl(href);
      const isLocal = external !== true && !isExternalLink(href);

      const isCurrent =
        isLocal && location.pathname === normalized.pathname
          ? location.search
            ? location.search === normalized.search
            : true
          : false;

      if (isCurrent && !ariaCurrent) setAriaCurrent("page");
      else if (!isCurrent && ariaCurrent) setAriaCurrent(undefined);
    }, [href, location, external, ariaCurrent]);

    return (
      <StyledAnchor
        aria-current={ariaCurrent}
        {...rest}
        href={href}
        onClick={handleClick}
        onMouseEnter={!isTouched ? handleTouched : onMouseEnter}
        onTouchStart={!isTouched ? handleTouched : onTouchStart}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        target={target}
        underline={underline}
        {...generateTrackingAttributes(tracking)}
      >
        {children || label}
      </StyledAnchor>
    );
  }
);

Link.displayName = "Link";

export default Link;
