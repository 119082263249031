import { IdProvider } from "@charlietango/use-id";
import { History } from "history";
import { useAtom } from "jotai";
import * as React from "react";
import { setConfig } from "react-hot-loader";
import { hot } from "react-hot-loader/root";

import { configAtom, metaAtom } from "@/store";
import { HtmlConfigViewModel } from "@/view-models/HtmlConfigViewModel";

import { ModuleInstance } from "./app-modules/modules-utils";
import AppShell from "./AppShell";
import pageLoader from "./page-loader";
import { Location } from "./Router";
import { RouteContext, useRouter } from "./Router";

type Props = {
  config: HtmlConfigViewModel;
  initialModules: Array<ModuleInstance>;
};

function buildUrl(location: Location) {
  const search = location.search ? `${location.search}` : "";

  return `${location.pathname}${search}${location.hash || ""}`;
}

if (
  process.env.NODE_ENV === "development" &&
  !process.env.SERVER &&
  process.env.GRAPHQL_MOCK &&
  "serviceWorker" in navigator
) {
  const { worker } = require("../mocks/browser");
  worker.start({ onUnhandledRequest: "bypass" });
}

function App({ initialModules }: Props) {
  const [config] = useAtom(configAtom);
  React.useEffect(() => {
    if (config.headless) pageLoader.configurePageLoader(config.headless);
  }, [config]);
  const router = useRouter();
  const mountedRef = React.useRef<boolean>(false);
  const [, setMeta] = useAtom(metaAtom);
  const modulesRef = React.useRef<Array<ModuleInstance>>(initialModules);

  // Store the currently rendered router in the state.
  // This is only updated once the location is ready to be rendered (e.g. all modules have been loaded)
  const [currentRouter, setCurrentRouter] = React.useState<History>(
    router as History
  );

  // Extract the locations from the router values
  const location = router.location;
  const currentLocation = currentRouter.location;

  const updateRouter = React.useCallback(
    () => setCurrentRouter(router),
    [router, setCurrentRouter]
  );

  const fetchNewPage = React.useCallback(
    async (pathname) => {
      const result = await pageLoader.fetchHeadlessPage(pathname);
      if (!mountedRef.current) return;
      if (result) {
        setMeta(result.meta);
        modulesRef.current = result.modules;
        updateRouter();
      } else {
        // If we didn't get a valid result, do a hard page change
        window.location.assign(buildUrl(location));
      }
    },
    [location, updateRouter, setMeta]
  );

  React.useEffect(() => {
    mountedRef.current = true;
    // If the pathname changes, fetch the new page
    if (location.pathname !== currentLocation.pathname) {
      // Fetch the new headless page
      fetchNewPage(location.pathname);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [router, location, location.pathname, currentLocation, fetchNewPage]);

  return (
    <RouteContext.Provider value={currentRouter}>
      <IdProvider>
        <AppShell
          isLoading={router !== currentRouter}
          modules={modulesRef.current}
          pathname={currentLocation.pathname}
        />
      </IdProvider>
    </RouteContext.Provider>
  );
}

if (module.hot) {
  // @ts-ignore code is correct, type in package is not
  setConfig({ logLevel: false });
}

export default process.env.NODE_ENV === "production" || process.env.SERVER
  ? App
  : hot(App);
