import React from "react";

type Props = {
  className?: string;
  fill?: string;
};

export default ({ className, fill }: Props) => (
  <svg
    className={className}
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={fill || "currentColor"} fillRule="evenodd">
      <path d="M11.997 4.001a7.947 7.947 0 0 0-5.59 2.279A7.946 7.946 0 0 0 4 11.91c-.051 4.411 3.497 8.04 7.908 8.09a7.971 7.971 0 0 0 5.684-2.279 7.942 7.942 0 0 0 2.406-5.629 7.95 7.95 0 0 0-2.278-5.683 7.955 7.955 0 0 0-5.63-2.408h-.094m.006 18L11.886 22C6.373 21.937 1.938 17.401 2 11.887A9.932 9.932 0 0 1 5.01 4.85a9.939 9.939 0 0 1 7.104-2.849 9.933 9.933 0 0 1 7.036 3.01 9.933 9.933 0 0 1 2.849 7.104 9.936 9.936 0 0 1-3.01 7.037 9.93 9.93 0 0 1-6.986 2.849" />
      <path d="M11.819 14.609c.575 0 1.02.456 1.02 1.02 0 .575-.445 1.019-1.02 1.019-.564 0-1.02-.444-1.02-1.02 0-.563.456-1.02 1.02-1.02zm-.768-.875c.096-1.548 1.931-2.315 1.931-3.443 0-.563-.432-.983-1.176-.983-.839 0-1.222.528-1.27 1.39H8.844c.048-1.654 1.188-2.89 3.071-2.89 1.775 0 2.83 1.092 2.83 2.399 0 1.776-1.943 2.339-2.099 3.527h-1.595z" />
    </g>
  </svg>
);
