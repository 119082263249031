import React from "react";

export type Props = {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
};

export default ({ className, width, height, fill }: Props) => (
  <svg
    aria-hidden="true"
    className={className}
    height={height || 12}
    viewBox="0 0 7 12"
    width={width || 7}
  >
    <path
      d="M1 .343L5.632 6 1 11.657"
      fill="none"
      stroke={fill || "currentColor"}
      strokeWidth="2"
    />
  </svg>
);
