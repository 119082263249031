import { css } from "styled-components";

import { DimensionNames } from "@/styles/media";
import { mediaOnly, mediaQuery } from "@/styles/media";
export type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const config = {
  gridSize: 12,
  container: {
    sm: 46,
    md: 61,
    lg: 78.75,
    hd: 90,
  },
};

/**
 * Create a correct media query for the style.
 * It handles:
 * - boolean value that always outputs the value.
 * - Specific dimension. Enables the styles for for all queries from the defined and up
 * - Array of dimensions. Enables the styles for only the specified queries
 **/
export function createQuery(
  queries?: boolean | DimensionNames | Array<DimensionNames>,
  ...args: Array<ReturnType<typeof css>>
): Array<ReturnType<typeof css>> | null | undefined {
  if (!queries) return null;
  if (queries === true) return args;
  if (Array.isArray(queries)) {
    return queries.map((query) => mediaOnly[query](args));
  }

  return mediaQuery[queries](args);
}

export default config;
