import { rem } from "polished";
import * as React from "react";
import styled from "styled-components";

import { mediaQuery } from "../../styles/media";
import { color } from "../../styles/theme";
import { heights } from "../../styles/theme";

const StyledMainElement = styled.main`
  background-color: ${color.bg};
  outline: none;
  min-height: calc(100vh - ${rem(heights.header.xs * 2)});

  ${mediaQuery.sm`
    min-height: calc(100vh - ${rem(heights.header.sm * 2)});
  `};
`;

type Props = {
  children: React.ReactNode;
};

/**
 * When the page is changed, we reset the focus to the top of the page using the <PageFocus> component
 * */
const Main = ({ children }: Props) => (
  <StyledMainElement id="main">{children}</StyledMainElement>
);

Main.displayName = "Main";

export default Main;
