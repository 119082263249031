import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";

import {
  GetUserQuery_bruger_session_BrugerSession_kan_repraesentere_Repraesentationsperson,
  RepraesentantRolle,
  Repraesentationsperson,
} from "@/graphql/__generated__/privateTypes";
import { authAtom } from "@/store";

export type SelectedRepresentee = {
  id: UUID | null;
  rolle: RepraesentantRolle | null;
};

interface Representee
  extends GetUserQuery_bruger_session_BrugerSession_kan_repraesentere_Repraesentationsperson {
  showRole?: boolean;
}

type Props = {
  maxResults: number;
};

const useRepresentatives = ({ maxResults }: Props) => {
  const [{ user }] = useAtom(authAtom);
  const [unfilteredRepresentees, setUnfilteredRepresentees] =
    useState<Array<Representee | null> | null>([]);
  const [selectedProperty, setSelectedProperty] = useState<
    "egne-ejendomme" | Repraesentationsperson | null
  >("egne-ejendomme");
  const [searchString, setSearchString] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  useEffect(() => {
    if (user?.repraesenterer) {
      const isRepresenting = user?.kan_repraesentere?.filter((representee) => {
        return (
          representee?.id === user?.repraesenterer?.id &&
          representee?.rolle === user?.repraesenterer?.rolle
        );
      })[0];

      if (isRepresenting) setSelectedProperty(isRepresenting);
    }
  }, [setSelectedProperty, user]);

  useEffect(() => {
    const representees = user?.kan_repraesentere?.length
      ? user?.kan_repraesentere
      : [];

    function checkForDuplicates(items) {
      const itemsSet = new Set();

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.showRole) break; // No need to check the rest of the array
        if (!itemsSet.has(item)) {
          for (let j = i + 1; j < items.length; j++) {
            const otherItem = items[j];
            if (item.navn === otherItem.navn) {
              item.showRole = true;
              otherItem.showRole = true;
              break; // No need to check the rest of the array
            }
          }
          itemsSet.add(item); // Add the current item to the set for next loop iteration
        }
      }
    }

    function sortByName(a, b) {
      if (a?.navn && b?.navn) {
        return a.navn.localeCompare(b.navn);
      } else if (a?.navn) {
        return -1;
      } else if (b?.navn) {
        return 1;
      }

      return 0;
    }

    checkForDuplicates(representees);

    const representeesWithLatestDocumentActivity = representees.filter(
      (r) => r?.seneste_dokument_aktivitet
    );
    const representeesWithoutLatestDocumentActivity = representees.filter(
      (r) => !r?.seneste_dokument_aktivitet
    );

    representeesWithLatestDocumentActivity.sort((a, b) => {
      if (a?.seneste_dokument_aktivitet && b?.seneste_dokument_aktivitet) {
        const dateA = new Date(a.seneste_dokument_aktivitet).getTime();
        const dateB = new Date(b.seneste_dokument_aktivitet).getTime();

        if (dateA !== dateB) {
          return dateA - dateB;
        } else {
          return sortByName(a, b);
        }
      } else if (a?.seneste_dokument_aktivitet) {
        return -1;
      } else if (b?.seneste_dokument_aktivitet) {
        return 1;
      }

      return 0;
    });

    representeesWithoutLatestDocumentActivity.sort((a, b) => sortByName(a, b));

    setUnfilteredRepresentees([
      ...representeesWithLatestDocumentActivity,
      ...representeesWithoutLatestDocumentActivity,
    ]);
  }, [user?.kan_repraesentere, setUnfilteredRepresentees]);

  const filteredRepresentees = React.useMemo(() => {
    const representees = unfilteredRepresentees ?? [];

    const searchTermsRegex = searchString
      .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      .split(" ")
      .filter((string) => string.trim() !== "")
      .map((searchTerm) => `(?=.*\\b${searchTerm})\\b`)
      .join("");

    const searchRegex = new RegExp(`${searchTermsRegex}.*`, "gi");

    const filtered = representees.filter(Boolean).filter((representee) => {
      if (searchString.length === 0) return true;

      const navn = representee?.navn;
      if (!navn) return false;

      return navn?.search(searchRegex) > -1;
    });

    setTotalPages(
      filtered.length > 0 ? Math.ceil(filtered.length / maxResults) : 0
    );

    setCurrentPage(0);

    return filtered;
  }, [maxResults, searchString, unfilteredRepresentees]);

  const pagedRepresentees = React.useMemo(() => {
    const sliceStart = currentPage * maxResults;
    const sliceEnd = sliceStart + maxResults;

    const pagedRepresentees = filteredRepresentees.slice(sliceStart, sliceEnd);

    return pagedRepresentees;
  }, [currentPage, filteredRepresentees, maxResults]);

  const goToPage = React.useCallback(
    (pageNumber: number) => {
      if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      }

      if (pageNumber < 0) {
        setCurrentPage(0);
      }

      setCurrentPage(pageNumber);
    },
    [totalPages]
  );

  return {
    user,
    pagedRepresentees,
    selectedProperty,
    setSelectedProperty,
    setSearchString,
    searchString,
    totalPages,
    currentPage,
    goToPage,
  };
};

export default useRepresentatives;
