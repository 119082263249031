import * as Sentry from "@sentry/browser";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";

import { captureMessage } from "./utils/error-logging";
import { I18nNamespacesViewModel } from "./view-models/I18nNamespacesViewModel";
import { I18nViewModel } from "./view-models/I18nViewModel";
import { KeyValueViewModel } from "./view-models/KeyValueViewModel";

const i18nextInstance = i18next;
const missingMap: {
  [key: string]: any;
} = {};

const defaultOptions: {
  [key: string]: any;
} = {
  fallbackLng: "da",
  load: "languageOnly",
  interpolation: {
    skipOnVariables: false,
  },
  ns: [],
  defaultNS: "common",
  saveMissing: !process.env.SERVER,
  // triggers resource loading in init function inside a setTimeout (default async behaviour).
  // Set it to false if your backend loads resources sync - that way calling i18nextext.t after init is possible without relaying on the init callback.
  initImmediate: false,
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    // This should be logged somewhere to notify the relevant people
    const id = lng + ns + key;
    if (!missingMap[id]) {
      captureMessage(
        `Missing i18next key: ${key}`,
        {
          lng,
          ns,
          key,
          fallbackValue,
        },
        Sentry.Severity.Warning
      );
      missingMap[id] = true;
    }
  },
  parseMissingKeyHandler: (key) => {
    // What should we display if a translation key is missing?
    return `#${key}#`;
  },
};

/**
 * Parse a schema dictionary, and add it the to i18n store
 * This dictionary is an array of key/value pairs
 */
export function addDictionary(
  dictionary: Array<KeyValueViewModel>,
  namespace?: string
) {
  const flat = dictionary.reduce((dictionary, field) => {
    dictionary[field.key] = field.value;

    return dictionary;
  }, {});

  if (namespace) addNamespaces({ [namespace]: flat });

  return flat;
}

/**
 * Add late discovered namespaces
 **/
export function addNamespaces(
  namespaces?: I18nNamespacesViewModel,
  merge = true
) {
  if (namespaces && i18next.isInitialized) {
    const keys = Object.keys(namespaces);
    keys.forEach((key) => {
      const lang = i18next.language || "da";
      const newNamespace = !i18next.hasResourceBundle(lang, key);

      if ((!newNamespace ? merge : true) && namespaces)
        i18nextInstance.addResourceBundle(lang, key, namespaces[key]);
    });
  }
}

export function initI18n(lang: string, config?: I18nViewModel) {
  const options = {
    ...defaultOptions,
    debug: false,
    lng: lang,
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: config && config.loadPath,
      // allow cross domain requests
      crossDomain: true,
      // allow credentials on cross domain requests
      withCredentials: true,
    },
  };

  if (process.env.SERVER) {
    // We need to create a new unique instance when rendering serverside.
    // Otherwise we risk the i18next instance already being defined, causing it set `ready` to true for namespaces that aren't actually loaded
    const i18nextInstance = i18next.createInstance(options);
    if (config) {
      // Add the initial namespace resources
      addNamespaces(config.namespaces);
    }

    return i18nextInstance;
  } else if (!i18next.isInitialized) {
    i18next.use(HttpApi).init(options);
  }

  if (config) {
    // Add the initial namespace resources
    addNamespaces(config.namespaces);
  }

  return i18next;
}
