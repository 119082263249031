import * as Sentry from "@sentry/browser";

import { captureException, captureMessage } from "@/utils/error-logging";
import { parseJSON } from "@/utils/request";
import { HtmlViewModel } from "@/view-models/HtmlViewModel";
import { MetaViewModel } from "@/view-models/MetaViewModel";

import { ModuleInstance } from "./app-modules/modules-utils";
import { preloadModules } from "./app-modules/modules-utils";

const TIMEOUT = 5000;
// The max amount of items to keep in the cache
export const HISTORY_LENGTH = 20;

export type PageResult = {
  pathname: string;
  meta: MetaViewModel;
  modules: Array<ModuleInstance>;
};

const config: { headless?: string } = {
  headless: "headless.json",
};

const pageCache = new Map();
const pathHistory: string[] = [];

/**
 * Configure the page loader defaults, so it can be used without knowing the headless string to append
 **/
function configurePageLoader(headless?: string) {
  config.headless = headless;
}

/**
 * Fetch the headless version of page, allowing us to update the running application
 **/
async function fetchHeadlessPage(
  pathname: string,
  headless?: string
): Promise<PageResult | null | undefined> {
  // Return the cached result
  if (pageCache.has(pathname)) return pageCache.get(pathname);

  // Start loading the path, and cache the promise so it can be reused for later requests
  const promise = asyncLoad(pathname, headless || config.headless);
  pageCache.set(pathname, promise);
  pathHistory.push(pathname);

  if (pathHistory.length > HISTORY_LENGTH) {
    // Start deleting items from the history, if it exceeds the HISTORY_LENGTH
    const firstEntry = pathHistory.shift();
    pageCache.delete(firstEntry);
  }

  return promise;
}

async function asyncLoad(pathname: string, headless?: string) {
  if (!headless) return null;
  try {
    const url = `${pathname.replace(/\/$/, "")}/${headless}`;
    // Try to fetch the route as headless JSON data.
    // Remove trailing '/' before adding headless query
    const promises: [Promise<HtmlViewModel> | any, Promise<null>] = [
      fetch(url, {
        credentials: "same-origin",
      }).then((res) => parseJSON(res)),
      new Promise((resolve, reject) =>
        setTimeout(() => {
          reject(new Error(`Request timed out while loading ${url}`));
        }, TIMEOUT)
      ),
    ];

    const result: HtmlViewModel | null = await Promise.race(promises);

    // If we got a valid JSON response with correct data, prepare to change the page to it.
    if (result && result.modules && result.meta) {
      // Preload the modules on the page, so all the scripts are ready
      const preloadedModules = await preloadModules(result.modules);

      if (module.hot && result) {
        // Cache the current page data, so when hot reloading the client can inject the correct initial state.
        // This only happens in dev
        global.__HOT_PAGE_DATA = result;
      }

      // If the result has required fields, update the page.
      return {
        meta: result.meta,
        modules: preloadedModules,
        pathname,
      };
    } else {
      captureMessage(
        "Trying to navigate to invalid page result",
        {
          url,
          result,
        },
        Sentry.Severity.Warning
      );
      pageCache.delete(pathname);

      return null;
    }
  } catch (e) {
    captureException(e, {
      pathname,
    });
    pageCache.delete(pathname);

    return null;
  }
}

export function reset() {
  pageCache.clear();
  pathHistory.length = 0;
  config.headless = "headless.json";
}

if (module.hot) {
  // If this module is reloaded, it should dispose the cache so it can be refreshed on next render
  module.hot.dispose(reset);
}

export default {
  asyncLoad,
  configurePageLoader,
  fetchHeadlessPage,
  reset,
};
