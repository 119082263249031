import * as React from "react";
import { Optional } from "utility-types";

import { captureException } from "@/utils/error-logging";

import AsyncComponent from "../AsyncComponent";
import { ModalProps } from "./Modal";
import { StepModalProps } from "./StepByStepModal";
import { VideoModalProps } from "./VideoModal";

function importModal(modal: string) {
  if (process.env.SERVER) {
    return null;
  } else {
    if (modal === "action")
      return import(
        /* webpackChunkName: 'action-modal' */
        "./ActionModal"
      );
    if (modal === "video")
      return import(
        /* webpackChunkName: 'video-modal' */
        "./VideoModal"
      );
    if (modal === "step-by-step")
      return import(
        /* webpackChunkName: 'step-by-step-modal' */
        "./StepByStepModal"
      );

    captureException(new Error("Tried to render unknown AsyncModal"), {
      modal,
    });

    return null;
  }
}

type Props = Optional<VideoModalProps & StepModalProps & ModalProps> & {
  modal: "video" | "step-by-step" | "action";
};

/**
 * Render a modal while loading it async.
 * Set the modal name you want to render, and it passes the props on to it
 **/
function AsyncModal({ modal, ...rest }: Props) {
  return <AsyncComponent loader={() => importModal(modal)} {...rest} />;
}

export default AsyncModal;
