import { rem } from "polished";
import { css, FlattenSimpleInterpolation } from "styled-components";

import { FontSizes } from "./fonts";

// Root font size
const ROOT_SIZE = 16;

/**
 * Clear the margin from first and last child, so they don't break the box
 * @param clear
 */
export const clearMargin = (clear = true) =>
  clear &&
  css`
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  `;

/**
 * This will hide the focus indicator if the element receives focus via the mouse,
 * but it will still show up on keyboard focus.
 * > https://github.com/WICG/focus-visible
 */
export const focusOutline = (outlineColor?: string) => css`
  &:focus {
    outline: 2px solid ${outlineColor || "currentColor"};
  }
  .js-focus-visible & :focus:not([data-focus-visible-added]) {
    outline: none;
  }
`;

/**
 * Change the font size responsively between two break points
 * @param minSize {number} Min font size in px
 * @param maxSize {number} Max font size in px
 * @param lower
 * @param upper
 */
export const responsiveFont = (
  minSize: number,
  maxSize: number,
  lower = 768,
  upper = 1200
): FlattenSimpleInterpolation => css`
  font-size: ${minSize / ROOT_SIZE}rem;

  @media (min-width: ${lower}px) {
    font-size: ${maxSize / ROOT_SIZE}rem;
  }
  @media (min-width: ${lower}px) and (max-width: ${upper}px) {
    /* prettier-ignore */
    font-size: calc(${minSize / ROOT_SIZE}rem + ${maxSize -
    minSize} * (100vw - ${lower}px) / ${upper - lower});
  }
`;

export function getFontSize(size: FontSizes) {
  switch (size) {
    case "tiny":
      return rem(13);
    case "small":
      return rem(14);
    case "large":
      return rem(20);
    case "normal":
    default:
      return "1rem";
  }
}
export function getLineHeight(size: FontSizes) {
  switch (size) {
    case "tiny":
      return 1.62;
    case "small":
      return 1.57;
    case "normal":
    default:
      return 1.63;
  }
}
