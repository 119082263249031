import * as React from "react";
import styled from "styled-components";

import BaseButton, { BaseButtonProps } from "@/components/Button/BaseButton";
import { durations } from "@/styles/animations";
import { getFontSize } from "@/styles/style-helpers";
import { color } from "@/styles/theme";
import { ButtonStateTypes } from "@/types/ButtonStateTypes";
import { TrackingTypes } from "@/types/TrackingTypes";
import generateTrackingAttributes from "@/utils/generateTrackingAttributes";
import { LinkViewModel } from "@/view-models/LinkViewModel";

import StyledCtaArrow from "./CtaArrow";

type Props = LinkViewModel & {
  children?: React.ReactNode;
  className?: string;
  /** Always render as anchor tag, don't fall back to button */
  isAnchor?: boolean;
  disabled?: boolean;
  size: "tiny" | "small" | "normal";
  iconPosition: "left" | "right";
  tracking?: TrackingTypes;
  onClick?: () => void;
  tabIndex?: string;
};

type AlignProps = {
  align: "left" | "right";
};

function getFontWeight({ size }) {
  if (size === "normal") return 600;
  if (size === "small") return 500;

  return 400;
}

const StyledBaseButton = styled(BaseButton)<
  BaseButtonProps & { size: "tiny" | "small" | "normal" }
>`
  && {
    display: inline-block;
    font-size: ${(props) => (props.size ? getFontSize(props.size) : "1rem")};
    font-weight: ${(props) => getFontWeight(props)};
    line-height: 1.25;
    text-align: left;
    max-width: 100%;
    transform: translateX(-0.5rem);
    color: ${(props) =>
      props.theme.style === "dark" ? color.lightGrey : color.blue};
  }
`;

const StyledContainer = styled.span<{ hover?: boolean }>`
  position: relative;
  padding: 0.5rem;
  margin: -0.5rem 0;
  display: inline-block;
  color: ${(p) => p.hover && p.theme.style === "dark" && color.white};
`;

const StyledLabel = styled.span<AlignProps & { hover?: boolean }>`
  display: inline-block;
  width: 100%;
  color: ${(p) => p.hover && p.theme.style !== "dark" && color.darkGrey};
  transition: color ${durations.fast} ease-out;
  margin-left: ${(p) => p.align === "left" && "22px"};
  padding-right: ${(p) => p.align === "right" && "22px"};
`;

export const StyledArrow = styled(StyledCtaArrow)<
  AlignProps & { hover?: ButtonStateTypes }
>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(p: AlignProps) => p.align === "left" && "0.5rem"};
  right: ${(p: AlignProps) => p.align === "right" && "0.5rem"};
`;

const InnerLink = ({
  iconPosition,
  hover,
  label,
  children,
}: {
  hover?: boolean;
  children: React.ReactNode;
  iconPosition: "left" | "right";
  label?: string;
}) => (
  <StyledContainer hover={hover}>
    {iconPosition === "right" && (
      <StyledLabel align={iconPosition} hover={hover}>
        {children || label}
      </StyledLabel>
    )}
    <StyledArrow align={iconPosition} hover={hover} />
    {iconPosition === "left" && (
      <StyledLabel align={iconPosition}>{children || label}</StyledLabel>
    )}
  </StyledContainer>
);

const CtaLink = ({
  children,
  label,
  tracking,
  iconPosition,
  ...rest
}: Props) => {
  const fallbackTracking: TrackingTypes = {
    section: "cta",
    eventName: "ctaClicks",
    name: (typeof children === "string" && children.toString()) || label,
  };

  return (
    <StyledBaseButton
      {...rest}
      {...generateTrackingAttributes(tracking || fallbackTracking)}
    >
      <InnerLink iconPosition={iconPosition} label={label} {...rest}>
        {children}
      </InnerLink>
    </StyledBaseButton>
  );
};

CtaLink.displayName = "CtaLink";
CtaLink.defaultProps = {
  size: "normal",
  iconPosition: "right",
};

export default CtaLink;
