import { findIndex, pathOr, pipe, update } from "ramda";

import type { Indsendelse as CurrentDraftType } from "@/graphql/__generated__/privateTypes";
import { ObjectionFormCheckBoxFieldViewModel } from "@/view-models/ObjectionFormCheckBoxFieldViewModel";
import { PageContextViewModel } from "@/view-models/PageContextViewModel";

import type {
  FormValues,
  PayloadFormSchema,
  PayloadFullSchema,
} from "../../types";
import { dateRegex } from "./formValidation";
import isValidJson from "./validateJson";

type ReformatPayloadType = {
  context: PageContextViewModel;
  values:
    | FormValues
    | {
        [key: string]: any;
      };
  currentDraft:
    | CurrentDraftType
    | {
        [key: string]: any;
      }
    | null
    | undefined;
  checkBoxProps?: ObjectionFormCheckBoxFieldViewModel;
  caseId?: string | null | undefined;
};

export default ({
  context,
  values,
  currentDraft,
  caseId,
  checkBoxProps,
}: ReformatPayloadType) => {
  let dateString = dateRegex.test(values?.dateField) ? values?.dateField : "";
  const { objectionFormConfig } = context;
  dateString =
    dateString.substr(6, 4) +
    "-" +
    dateString.substr(3, 2) +
    "-" +
    dateString.substr(0, 2);

  const indsigelse: PayloadFormSchema = {
    indsigelsestype: objectionFormConfig?.formType || "",
    indsigelseskode: objectionFormConfig?.formCode || "",
    aarsagskode: objectionFormConfig?.causeCode || "",
    kort_tekst: values?.textFieldWithLimit || "",
    lang_tekst: values?.textField || "",
    dato:
      dateString && dateString.length > 2
        ? new Date(dateString).toISOString()
        : "",
    checkbox: Boolean(
      values?.checkBoxField && values?.checkBoxField.length > 0
    ),
    checkbox_text: checkBoxProps?.title || "",
    dokumentation_filer: values?.attachFileField
      ? values?.attachFileField.files
      : [],
  };

  const extractedCurrentObjectionPayload: PayloadFullSchema = pipe(
    pathOr("{}", ["indhold"]), // return the actual payload string
    JSON.parse, // Parse to object
    pathOr([], ["indsigelser"]) // find indsigelser
  )(currentDraft);

  //find the indsigelse index that should be replaced
  const index = findIndex(
    (objection) => objection.indsigelseskode === objectionFormConfig?.formCode,
    extractedCurrentObjectionPayload
  );

  let newCurrentObjection: PayloadFullSchema = [];
  if (index > -1) {
    newCurrentObjection = update(
      index,
      indsigelse,
      extractedCurrentObjectionPayload
    ); //replace indsigelse with updated data
  } else {
    extractedCurrentObjectionPayload.push(indsigelse);
    newCurrentObjection = extractedCurrentObjectionPayload;
  }

  //extract the payload from current draft and update it with newCurrentObjection
  const newPayload = pipe(pathOr("{}", ["indhold"]), JSON.parse)(currentDraft);

  newPayload.indsigelser = newCurrentObjection;
  newPayload.vurderingsejendom_id = currentDraft?.vurderingsejendom_id || 0;
  newPayload.vurderingsaar = currentDraft?.vurderingsaar || 0;
  newPayload.sag_id = caseId || "";

  isValidJson(newPayload);

  return JSON.stringify(newPayload);
};
