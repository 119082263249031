import * as React from "react";

import { captureMessage } from "@/utils/error-logging";

type Props = {
  attributes: string;
};

type InternalProps = {
  text: string;
};

function Underline({ attributes }: Props) {
  try {
    const { text }: InternalProps = JSON.parse(attributes);

    return <u>{text}</u>;
  } catch (e) {
    // Invalid Json
    captureMessage(
      "Tried to render Underline with invalid JSON: " + attributes,
      e
    );
  }

  return null;
}

Underline.displayName = "Underline";
Underline.defaultProps = {};

export default Underline;
