import * as React from "react";

type Props = {
  /** Specifically loader is a function that returns a promise. The promise should resolve to a renderable React component. */
  loader: () => Promise<any> | null | undefined;
  renderPlaceholder?: () => JSX.Element;
  [x: string]: any;
};

const AsyncComponent = ({
  renderPlaceholder,
  loader,
  ...rest
}: Props): JSX.Element | null => {
  const loadedComponent = React.useRef<
    React.ComponentType<any> | null | undefined
  >(undefined);
  const [isReady, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    let promise;

    if (loader) {
      promise = loader();
      promise &&
        promise.then((result) => {
          // Validate that the component hasn't been unmounted.
          if (!promise) return;
          // This fallback to just using result is mostly for
          // legacy support of third party packages with module.exports syntax.
          loadedComponent.current = result.default || result;
          setReady(!!loadedComponent.current);
        });
    }

    return () => {
      loadedComponent.current = undefined;
      promise = undefined;
    };
  }, [loader]);

  if (isReady && loadedComponent.current) {
    return React.createElement(loadedComponent.current, rest);
  }

  return renderPlaceholder ? renderPlaceholder() : null;
};

AsyncComponent.displayName = "AsyncComponent";

export default AsyncComponent;
