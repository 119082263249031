export const baseDurations = {
  slow: 0.5,
  medium: 0.3,
  fast: 0.1,
};

export const durations = {
  slow: `${baseDurations.slow}s`,
  medium: `${baseDurations.medium}s`,
  fast: `${baseDurations.fast}s`,
};

/**
 * React spring preset
 * */
export const presets = {
  fast: { tension: 170, friction: 16, clamp: true },
  stiff: { stiffness: 200, friction: 23, clamp: true },
};

/**
 * Frame-motion transition
 **/
export const transitions = {
  tween: {
    type: "tween",
    duration: baseDurations.medium,
  },
  spring: {
    type: "spring",
    stiffness: 170,
  },
};
