import * as React from "react";
import styled from "styled-components";

import { color } from "@/styles/theme";

type Props = {
  children?: React.ReactNode;
};

const StyledTableWrapper = styled.div`
  overflow-x: auto;
  table {
    border: 1px solid ${color.borderGrey};
    display: block;
    width: fit-content;
    white-space: nowrap;
  }
  td {
    border-right: 2px solid ${color.bg};
    border-bottom: 2px solid ${color.bg};
    text-align: start;
    padding: 40px;
    color: ${color.altBlue};
    white-space: normal;
  }
  span:has(img) {
    margin: 0px;
  }
  td:last-child {
    border-right: 0px;
  }
  tbody {
    border: 1px solid ${color.bg};
    background-color: ${color.white};
  }
  th {
    text-align: start;
    padding: 40px;
    border-bottom: 5px solid ${color.bg};
  }
  thead {
    background-color: ${color.white};
  }
`;

const isImageRenderer = (item) =>
  item.type && item.type.displayName === "ImageRenderer";

function TableRenderer(props: Props) {
  const Children = React.Children.toArray(props.children).reduce(
    (elements: Array<any>, element) => {
      if (isImageRenderer(element)) {
        // Images are always rendered in their own "table cell"
        elements.push(element);
      } else {
        // All other children are added to the list
        if (!elements.length || !Array.isArray(elements[elements.length - 1])) {
          (elements as Array<any>).push([]);
        }
        elements[elements.length - 1].push(element);
      }

      return elements;
    },
    []
  );

  return (
    <StyledTableWrapper>
      {(Children as Array<any>).map((child) => {
        return <table>{child}</table>;
      })}
    </StyledTableWrapper>
  );
}

TableRenderer.displayName = "TableRenderer";
TableRenderer.defaultProps = {};

export default TableRenderer;
