import { rem } from "polished";
import * as React from "react";
import styled, { css } from "styled-components";

import { DimensionNames } from "@/styles/media";

import { createQuery } from "./grid-config";

export type Props = {
  // TODO: How to only allow Col type component
  children?: React.ReactNode;

  /** Gutter in 10px increments */
  xsGutter?: number;
  smGutter?: number;
  mdGutter?: number;
  lgGutter?: number;
  hdGutter?: number;
  noWrap?: boolean;
  reverse?: boolean | DimensionNames | Array<DimensionNames>;
  start?: boolean | DimensionNames | Array<DimensionNames>;
  center?: boolean | DimensionNames | Array<DimensionNames>;
  end?: boolean | DimensionNames | Array<DimensionNames>;
  top?: boolean | DimensionNames | Array<DimensionNames>;
  middle?: boolean | DimensionNames | Array<DimensionNames>;
  bottom?: boolean | DimensionNames | Array<DimensionNames>;
  around?: boolean | DimensionNames | Array<DimensionNames>;
  between?: boolean | DimensionNames | Array<DimensionNames>;
  first?: boolean | DimensionNames | Array<DimensionNames>;
  last?: boolean | DimensionNames | Array<DimensionNames>;
  as?: string;
};

export const gutterValOuter = (gutter: number) => rem((gutter * 10) / 2 - 1);

function addGutter(key: DimensionNames | boolean, gutter?: number) {
  if (gutter) {
    const gutterVal = rem((gutter * 10) / 2);

    return createQuery(
      key,
      css`
        margin-right: -${gutterValOuter(gutter)};
        margin-left: -${gutterValOuter(gutter)};

        > div,
        > th,
        > td {
          padding-right: ${gutterVal};
          padding-left: ${gutterVal};
        }
      `
    );
  }

  return null;
}

export const rowStyles = css<Props>`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: ${(p) => (p.noWrap ? "nowrap" : "wrap")};

  ${(p: Props) => addGutter("xs", p.xsGutter)}
  ${(p: Props) => addGutter("sm", p.smGutter)}
  ${(p: Props) => addGutter("md", p.mdGutter)}
  ${(p: Props) => addGutter("lg", p.lgGutter)}
  ${(p: Props) => addGutter("hd", p.hdGutter)}
  ${(p: Props) =>
    createQuery(
      p.reverse,
      css`
        flex-direction: row-reverse;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.start,
      css`
        justify-content: flex-start;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.center,
      css`
        justify-content: center;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.end,
      css`
        justify-content: flex-end;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.top,
      css`
        align-items: flex-start;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.middle,
      css`
        align-items: center;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.bottom,
      css`
        align-items: flex-end;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.around,
      css`
        justify-content: space-around;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.between,
      css`
        justify-content: space-between;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.first,
      css`
        order: -1;
      `
    )}

  ${(p: Props) =>
    createQuery(
      p.last,
      css`
        order: 1;
      `
    )}
`;

const Row: React.FunctionComponent<Props> = styled.div`
  ${rowStyles}
`;

Row.displayName = "Row";

export default Row;
