import { rem } from "polished";
import { css } from "styled-components";

import { color } from "../theme";

export const label = css`
  font-size: ${rem(13)};
  line-height: 22px;
  color: ${color.darkGrey};
  font-weight: normal;
`;
