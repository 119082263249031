import { useEffect, useRef } from "react";

import useIsomorphicLayoutEffect from "@/utils/hooks/useIsomorphicLayoutEffect";

import { scrollToElement } from "../utils/scroll-to-element";
import { useRouter } from "./Router";

/**
 * Small component that listens for changes in the Router location, and ensures the viewport
 * is scrolled to the to top when changing path. Only runs when a new path is pushed (e.g. the user clicked a link).
 * If the user navigates backward in history (using the browser navigation), it will instead trigger a POP event. In that case
 * we let the browser itself set the current scroll position.
 */
function ScrollRestoration() {
  const { action, location } = useRouter();
  const prevLocation = useRef(location);
  useIsomorphicLayoutEffect(() => {
    // The layout effect runs before rendering, so we can use this to update the scroll position early.
    if (
      action === "PUSH" &&
      !location.hash &&
      location.pathname !== prevLocation.current.pathname
    ) {
      // Reset scroll to the top when PUSHing a new path
      scrollToElement("#app", undefined, true);
    }

    // Store a reference to location, so we can compare the pathname on a new render
    prevLocation.current = location;

    return () => {};
  }, [location, action]);

  useEffect(() => {
    if (action === "PUSH" && location.hash) {
      // If the hash value changes, perform a smooth scroll
      scrollToElement(location.hash, "smooth", true);
    }
  }, [location, action]);

  return null;
}

export default ScrollRestoration;
