import { useAtom } from "jotai";
import { rem } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Placeholder from "@/components/Placeholder/Placeholder";
import ErrorPage from "@/modules/Layout/ErrorPage/ErrorPage";
import { authAtom, authGuardAtom, forbiddenErrorAtom } from "@/store";
import { mediaQuery } from "@/styles/media";
import { heights } from "@/styles/theme";
import { generateRedirectUrl } from "@/utils/url-helpers";

type Props = {
  children: React.ReactNode;
  behindAuth: boolean;
  loginUrl: string;
};

const StyledPlaceholderWithMinHeight = styled(Placeholder)`
  height: auto;
  max-height: none;
  min-height: calc(100vh - ${rem(heights.header.xs * 2)});

  ${mediaQuery.sm`
    min-height: calc(100vh - ${rem(heights.header.sm * 2)});
  `};
`;

function AuthenticationGuard(props: Props): JSX.Element | null {
  const { children, loginUrl, behindAuth } = props;
  const [errorPage] = useAtom(authGuardAtom);
  const [{ user, authenticating }] = useAtom(authAtom);
  const [forbiddenError] = useAtom(forbiddenErrorAtom);
  const { t, ready } = useTranslation("auth");

  if (behindAuth === false) return <>{children}</>;

  if (!ready || authenticating || forbiddenError)
    return <StyledPlaceholderWithMinHeight />;
  if (!user)
    return (
      <ErrorPage
        ctas={[
          {
            label: t("behindAuthButtonLabel"),
            href: generateRedirectUrl(loginUrl),
            external: true,
          },
        ]}
        description={t("behindAuthBody")}
        title={t("behindAuthHeadline")}
      />
    );

  if (errorPage) {
    return (
      <ErrorPage
        ctas={
          errorPage?.ctas
            ? errorPage.ctas.map((cta) => ({ ...cta, label: t(cta.label) }))
            : undefined
        }
        description={[t(errorPage.description)]}
        title={t(errorPage.title)}
      />
    );
  }

  return <>{children}</>;
}

AuthenticationGuard.displayName = "AuthenticationGuard";
AuthenticationGuard.defaultProps = {};

export default AuthenticationGuard;
